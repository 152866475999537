import React from 'react'
import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from '@material-ui/core';
import moment from 'moment';

const NewReservationDialog = (props) => {
    const reservationsLength = Object.values(props.showNotificationsReservations).length;
    console.log('props.showNotificationsReservations', props.showNotificationsReservations);

    return (

        <Dialog
            open={props.open}
            // onClose={props.onClose}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
            className={"mui-dialog-title-info"}
        >
            <DialogTitle id="alert-dialog-title">
                {/* {props.translations.common.info} */}
                {reservationsLength == 1 ? 'Нова резервация' : 'Нови резервации ' + reservationsLength + ' бр.'}

            </DialogTitle>
            <DialogContent className={"mui-dialog-content"}>
                <DialogContentText id="alert-dialog-description">
                    {/* {props.translations.orders.activete_sound_dialog_title} */}

                    {Object.values(props.showNotificationsReservations).map(r => <>
                        Име:{r.client_name} <br />
                        Дата на рез.: {moment(r.start_time).format('DD.MM.YYYY')}<br />
                        Час на рез.: <span style={{ background: '#ebebeb', borderRadius: 5 }}>{moment(r.start_time).format('HH:mm')} - {moment(r.end_time).format('HH:mm')}</span><br />
                        Тел. номер:{r.client_phone} <br />
                        Маси: [{Object.values(r.tables).map((tt, kk) => tt.table_num + '' + ((kk != Object.values(r.tables).length - 1) ? ',' : ''))}]
                        <br />Меню: {r.menu_included ? <b>ДА</b> : 'НЕ'} / Избрана маса: {r.table_choosed ? <b>ДА</b> : 'НЕ'} / Капаро: {r.paid_ammount ? <b>{r.paid_ammount} лв.</b> : null}  /
                        <br />Описание: {r.description}
                        <hr />
                    </>)}

                </DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button onClick={e => props.onClose(e, Object.values(props.showNotificationsReservations).reduce((max, obj) => obj.id > max ? obj.id : max, 0))} className={"mui-color-secondary"} autoFocus>
                    {props.translations.common.close}
                </Button>
            </DialogActions>
        </Dialog>
    )
}

export default NewReservationDialog;


