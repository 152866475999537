export const enTranslations = {
    sidemenu: {
        alergens: "Alergens",
        dashboard: "Dashboard",
        menu: "Menu",
        categories: "Categories",
        categoriesHierarchy: "Categories",
        subcategories: "Subcategories",
        products: "Products",
        administration: "Administration",
        tables: "Tables",
        orders: "Orders",
        all_orders: "All orders",
        orders_from_spot: "From the place",
        orders_delivery: "With delivery",
        orders_preorder: "With preorder",
        orders_room: "From room",
        deleted_orders: "Orders /deleted/",
        charts: "Charts",
        reservations: "Reservations",
        locations: "Locations",
        rooms: "Rooms",
        reservation_tables: "Reservation tables",
        schedule: "Schedule",
        settings: "Settings",
        users: "Users",
        system: "System",
        super_admin: "Super admin",
        current_place: "Current place",
        clients: "Clients",
        place: "Place",
        places: "Places",
        ingredients: "Ingredients",
        statistics: "Statistics",
        statistics_users: "Statistics - users",
        statistics_orders: "Statistics - orders",
        delivery_regions: "Delivery regions",
        feedback: "Feedback",
        feedback_questions: "Feedback - questions",
        feedback_answers: "Feedback - answers",
        work_users: "Work users",
        registered_users: "Registered users",
        invoices: "Invoices",
        auto_invoices: "Auto Invoices",
        proforms: "Proforms",
        bookings: "Bookings",
        promocodes: "Promocodes",
        general_promotions: "General ads",
        place_images: "Gallery"
    },
    promocodes: {
        title: 'Promocode',
        add: 'Add promocode',
        edit: 'Edit promocode'
    },
    common: {
        notification: 'Notifications',
        notifications: 'Notifications',
        create: 'Create',
        show_cat_only_for_spot_orders_text: "Show only for spot orders",
        hide_cat_only_for_spot_orders_text: "Hide only for spot orders",
        is_paying: "Paying",
        next: 'Next',
        count_promo_codes: 'Count promocodes',
        ordertype: 'Order type',
        promocode: 'Promocode',
        discount_value: 'Discount - value',
        discount_type: 'Discount - type',
        from: 'From',
        to: 'To',
        discount: 'Discount',
        valid: 'Valid',
        used: 'Used',
        not_used: 'Not used',
        valid_from: 'Valid from',
        valid_to: 'Valid to',
        months: {
            1: 'January',
            2: 'February',
            3: 'March',
            4: 'April',
            5: 'May',
            6: 'June',
            7: 'July',
            8: 'August',
            9: 'September',
            10: 'October',
            11: 'November',
            12: 'December',
        },
        completed: 'Completed',
        prefix: "Prefix",
        place_in_url: 'Add place in QR code link',
        number_start: 'Номер от',
        number_end: 'Номер до',
        add_bulk: "Bulk add",
        no_internet: 'No internet connection',
        day_1: 'Monday',
        day_2: 'Tuesday',
        day_3: 'Wednesday',
        day_4: 'Thursday',
        day_5: 'Friday',
        day_6: 'Saturday',
        day_7: 'Sunday',
        max_count_addable_ingredients: 'Max count',
        min_count_addable_ingredients: 'Min count',
        hide_variants: 'Hide variants in all products page',
        active_in: 'Acrive in:',
        warning: 'Warning',
        info: 'Information',
        color: "Color",
        back: "Back",
        included: 'included',
        link: 'Link',
        box: "Box",
        boxes: "Box",
        done: "Done",
        background: "Background",
        color: "Color",
        title: "Title",
        paid: "Paid",
        waitings: "Waiting",
        order: "Order",
        alergens: "Alergens",
        id: "Id",
        dashboard: "Dashboard",
        category_name: "Category name",
        product_name: "Product name",
        name: "Name",
        short_name: "Short name",
        icon: "Icon",
        status: "Status",
        action: "Action",
        category: "Category",
        client: "Client",
        subcategory: "Subcategory",
        size: "Size",
        price: "Price",
        is_drink: "Is drink",
        number: "Number",
        order_number: "Order number",
        order_type: "Order type",
        date: "Date",
        turnover: "Turnover",
        code: "Code",
        active_text: "Active",
        active: "Active",
        inactive: "Inactive",
        description: "Description",
        image: "Image",
        yes: "Yes",
        no: "No",
        add: "Add",
        cancel: "Cancel",
        save: "Save",
        delete: "Delete",
        close: "Close",
        edit: "Edit",
        price_of_variant: "Price of variant",
        table: "Table",
        room: "Room",
        bill_wanted: "Bill wanted",
        bill: "Bill",
        currency: "lv.",
        download: "Download",
        value: "Value",
        images: "Images",
        rows: "Rows",
        type: "Type",
        restaurant_order: "Restaurant order",
        delivery: "Delivery",
        preorder: "Pre-Order",
        delivery_and_preorder: "Delivery and Pre-Order",
        restaurant_and_delivery: "Restaurant and Delivery",
        restaurant_and_preorder: "Restaurant and Pre-Order",
        others: "Others",
        role: "Role",
        additional_price: "Extra price",
        slug: "Slug",
        cash: "Cash",
        on_room_bill: "On room",
        card: "With card",
        level: "Level in hierarchy",
        parent_category: "Parent category",
        filter: "Filter",
        place: "Place",
        activeDaysOfWeek: {
            1: "Monday",
            2: "Tuesday",
            3: "Wednesday",
            4: "Thursday",
            5: "Friday",
            6: "Saturday",
            7: "Sunday",
        },
        types: {
            default: 'Default',
            image: 'Image',
            info: 'Info',
            link: 'Link',
            booking: 'Booking'
        },
        activeStartTime: "Active start time",
        activeEndTime: "Active end time",
        is_promo_product: "Promo product (Pops up in category. Won't be listed in the menu)",
        is_promo_product_info: "Popups a window for selected category. After beign activated it can be set from the edit category page, tab 'Promo products for category'",
        is_image_cat: "Image category",
        priority: "Priority",
        priority_description: "The smaller the number is displayed earlier (1 - first, 2 - second ..)",
        start_date: "Start date",
        end_date: "End date",
        add_row: "Add row",
        remove_row: "Remove row",
        delivery_price: "Delivery price",
        price_over: 'Over (price)',
        price_over_info: "Order price over which the 'price' will be added",
        rotation_start_date: "Rotation start date",
        rotation_start_date_info: "The category will appear in the menu on a rotational basis every {Rotation days count}",
        rotation_days_count: "Rotation days count",
        rotation_days_count_info: "The category will be visible each X rotation days",
        order_allowed: "Order allowed",
        order_allowed_info: "Enable/Disable orders for category category",
        hide_for_ordering: "Hide product",
        hide_for_ordering_info: "Hides the product from the menu",
        fast_order_info: "Switch fast order banner visibility from Settings > Banners",
        image_scale: "Image scale",
        discount_percent: "Discount percent",
        discount_money: "Money discount",
        discount_percent_info: "Sets discount percent for all products in this category",
        deleted: "Deleted",
        isLiquid: "Is Liquid",
        label: "Label",
        room_number: "Room number",
        remove_current_worktime: "Маркирай като Неработен",
        save_current_worktime_for_whole_week: "Save this work time for the whole week",
        question: "Question",
        answer: "Answer",
        approved: "Approved",
        not_approved: "Not approved",
        user: "User",
        orders: "Orders",
        total_amount: "Total amount",
        booking_duration: "Booking duration",
        table_or_room: "Table/Room",
        on_order_number: "Order number on which the discount will be activated",
        ws_code: "WS code",
        with_ws_code: "With code",
        without_ws_code: "Without code",
        productPrintGroupId: "Print group",
        choose_product_print_group: "Please choose print group",
        show_only_for_spot_browse: "Show only for spot browse (hides for delivery, pickup, etc)",
        hide_only_for_spot_browse: "Hide only for spot browse (shows for delivery, pickup, etc)",
        show_cat_only_for_delivery_orders_text: "Show only for delivery",
        hide_cat_only_for_delivery_orders_text: "Hide only for delivery",
        external_system_region_id: "External system region id",
        city: 'City',
        hour: 'Hour',
        payment_type: 'Payment type',
        personal_discount: "Personal discount",
    },
    versions: {
        features_about_version: "New features about version:",
        version: "Version"
    },
    alergens: {
        title: "Alergens",
        add: "Add alergen",
        edit: "Edit alergen",
        delete: {
            title: "Delete alergen",
            confirm: "Are you sure you want to delete "
        }
    },
    places: {
        title: "Places",
        add: "Add place",
        edit: "Edit place",
        ordertypes: "Order types",
        edit: "Edit",
        phone: "Phone number for SMS for unseen orders *",
        delete: {
            title: "Delete place",
            confirm: "Are you sure you want to delete "
        },
        copy_menu_from: "Copy menu from:",
        init_settings_for_all_places: "Init settings for all places",
        functionality_modules: "Functionality modules",
        sms_logs: "SMS's by months",
        additional_info: "Additional info",
        company_email: "Email",
        lat: "Lat",
        lng: "Lng",
        visible_on_map: "Visible on map",
        city: "City",
        tags: "Tags (for filtering on the map page)",

    },
    categories: {
        title: "Categories",
        description: "Description",
        add: "Add category",
        edit: "Edit category",
        delete: {
            title: "Delete category",
            confirm: "Are you sure you want to delete "
        },
        tabs: {
            edit: "Edit ",
            promo_products_for_category: "Promo products for category",
            images_category: "Images category",
            booking: "Booking",
            video: "Video"
        },
        places_for_category: "Places for category to appear in",
        promo_title: "Promo popup title",
        booking_start_work_time: "Booking start work time",
        booking_end_work_time: "Booking end work time",
    },
    subcategories: {
        title: "Subcategories",
        add: "Add subcategory",
        edit: "Edit subcategory",
        delete: {
            title: "Delete subcategory",
            confirm: "Are you sure you want to delete "
        }
    },
    products: {
        replace_image: "Change photo",
        upload_image: "Upload image",
        fast_order: "Visible in fast order Banner",
        title: "Products",
        title_single: "Product",
        ingredients: "Ingridients",
        variants: "Variants",
        add: "Add product",
        add_bulk: "Add products in bulk",
        edit: "Edit product",
        add_variant: "Add variant",
        save_variant: "Save variant",
        delete_image: "Delete image",
        delete: {
            title: "Delete product",
            confirm: "Are you sure you want to delete "
        },
        related_products: "Related products",
        related_products_info: "Когато бъде добавен в количката продукта, 'Свързаните продукти' изскачат като попъп с цел upselling (Клиента добавя бургер, изскача му попъп с предложение за картофки)",
        bulk_update_products_field: "Update many products",
        bulk_update_products_field_info: "The chosen field of all filtered products will be updated. Use with caution!",
        ws_code: "Warehouse code",
        ws_code_text: "Warehouse system product code",
        sync_products: "Sync products",
        sync_products_confirmation: "This will sync the names and prices of the products with the ones in your Warehouse system. Are you sure?",
        show_only_for_spot_browse: "Show only for spot browse",
        hide_only_for_spot_browse: "Hide only for spot browse",
        comment_placeholder: "Comment placeholder"
    },
    product_variants: {
        delete: {
            title: "Delete variant",
            confirm: "Are you sure you want to delete "
        },
        ws_code_text: "Variant code in warehouse system. Takes precedence over product code in warehouse system",
    },
    ingredients: {
        addable: "Addable ingridients",
        removable: "Removeable ingridients",
        required: "Required ingridients",
        title: "Ingredients",
        add: "Add ingredient",
        edit: "Edit ingredient",
    },
    orders: {
        activete_sound_dialog_title: 'This screen will notify you with sound and vibration (for mobile phones)',
        select_tables: "Select tables",
        no_data: "No data in the selected tab",
        hide_deleted: "Hide deleted",
        show_deleted: "Show deleted",
        hide_completed: "Hide completed",
        show_completed: "Show completed",
        client_name: "Client name",
        phone: "Phone",
        client_phone: "Client phone",
        client_address: "Client address",
        courier: "Courier",
        products: "Products",
        order_date: "Order time",
        order_date_short: "Ordered",
        update_date: "Update time",
        update_date_short: "Updated",
        type: "Order type",
        user_needs: "User wishes",
        payment_type: "Payment type",
        serve_time: "Serve time: ",
        client_pickup_time: "Pickup time: ",
        client_delivery_time: "Delivery time: ",
        room_number: "Room number: ",
        select_table_regions: "Select table regions",

        //'ordered','cooked_spot','delivered_spot','cooked_delivery','on_the_way_delivery','cooked_preorder','paid'

        user_needs_list: {
            "clean_table": "Clean table",
            "ice": "Ice",
            "napkins": "Napkins",
            "new_cutlery": "New cutlery",
            "change_ashtray": "Change ashtray",
            "other": "Other"
        },

        types: {
            delivery: "Delivery",
            preorder: "With preorder",
            spot: "From place",
            room: "From room",
            tablet_mode: "Tablet mode",
            served_on_spot: "Served on spot"
        },

        statuses: {
            ordered: "Ordered",
            cooked_spot: 'Cooked',
            cooked_room: 'Cooked',
            served_spot: 'Served',
            cooked_delivery: 'Cooked',
            on_the_way_delivery: 'Delivering',
            cooked_preorder: 'Cooked',
            paid: "Paid",
            rejected: "Reject order"
        },

        all: "All",
        new: "New",
        ordered: "Ordered",
        cooked: "Cooked",
        served: "Served",
        for_delivering: "For delivering",
        delivering: "Delivering",
        waiting_for_pickup: "Waiting pickup",
        paid: "Paid",
        future: "Future",
        delivered: "Delivered",

        delete: {
            title: "Delete order",
            confirm: "Are you sure you want to delete order # "
        },
        delivery_date_short: "Delivery at",
        pickup_date_short: "Pickup at",
        connected_to_printer: "CONNECTED TO PRINTER",
        connect_to_printer: "CONNECT TO PRINTER",
        connect_to_mini_comp: "CONNECT TO MINI COMP.",
        connected_to_mini_comp: "CONNECTED TO MINI COMP.",
        push_notifications: "Push notifications",
        delete_all: "Delete all for today",
        print_all_today: "Print all for today",
        warehouse_errors_modal_title: "Warehouse system - error",
        paid_online: "PAID ONLINE",
        paid_with_epay: "Paid with epay",
    },
    messages: {
        errors: {
            invalid_code: 'Invalid code',
            invalid_name: "Invalid name",
            invalid_category: "Invalid category",
            invalid_subcategory: "Invalid subcategory",
            invalid_size: "Invalid size",
            invalid_price: "Invalid Price",
            invalid_table_num: "Invalid table num",
            invalid_table_num_start: "Invalid table num start",
            invalid_table_num_end: "Invalid table num end",
            invalid_email: "Invalid email",
            invalid_role: "Invalid role",
            you_must_select_place: "You must select a place",
            no_data_to_insert: "Empty data",
            invalid_price_over: "Invalid price over",
            invalid_crop_image: "You must upload and crop the image",
            invalid_slug: "Invalid slug",
            invalid_ordertype: "Invalid order type",
            invalid_place: "Invalid place",
            invalid_credentials: "Invalid credentials",
            reached_max_rows_save_to_proceed: 'You reached the max number of rows. Please save them in order to proceed',
            sound_not_found: 'Sound is not imported! You will not be notified for new orders! Please contact the support!',
            invalid_start_work_time: 'Invalid start work time',
            invalid_end_work_time: 'Invalid end work time',
            invalid_min_people_count: "Invalid min people count",
            invalid_max_people_count: "Invalid max people count",
            invalid_tables: "You must choose tables",
            min_people_must_be_less_than_max_people: "Min people must be less than max people",
            invalid_city: "You must choose city",
            invalid_phone: "Insert phone number for sms notifications for unseen orders or insert 0 ONLY for testing clients.",
            radius_end_must_be_bigger_than_radius_start: "Radius end must be bigger than radius start",
            radius_end_required: "Radius end is required",
            radius_start_required: "Radius start is required",

        }
    },
    tables: {
        title: "Tables",
        add: "Add table",
        edit: "Edit table",
        delete: {
            title: "Delete table",
            confirm: "Are you sure you want to delete table "
        },
        generate_qr: "Generate new QR code",
        is_for_reservation: "Active for reservation",
        reservation_min_people: "Min people count for reservation",
        reservation_max_people: "Max people count for reservation",
        restaurant_hall: "Restaurant hall",
        ordertype: "Order type",
        table_region: "Region",
        is_for_app: "For menu app - table to use for login in menu app",
        is_kiosk_mode: "For kiosk mode - table for kiosk orders",
    },
    settings: {
        menu: "Menu",
        kitchen: "Kitchen system",
        admin: "Admin",
        general: "General settings",
        background_image: "Background image",
        logo: "Logo",
        default_lang: {
            default: "Default menu language",
            bg: 'Bulgarian',
            en: 'English',
            ro: 'Romanian',
            es: "Spanish"
        },
        first_init_lang: {
            default: "First init language - Google translate",
            without_initial_translate: "Without initial translation",
            bg: 'Bulgarian',
            en: 'English',
            ro: 'Romanian',
        },
        translations_type: {
            default: "Translation type in the menu",
            visible_description: "- ENNПри Google Translate превода става автоматично. <br/><br/>- При стандартния превод, за да се отразят различните преводи на менюто при смяна на език е необходимо да се преведат ръчно всички категории,продукти..",
            1: "Google Translate",
            2: "Default translation",
        },
        change_language_position: {
            default: "Position of translate button in the menu",
            visible_description: "2",
            header: "Header",
            footer: "Footer",
        },
        default_currency_suffix: {
            default: "Currency",
            visible_description: "1235",
        },
        view_product_page: {
            default: "View product page",
            0: "No",
            1: "Yes - without icon for adding product",
            2: "Yes - with icon for adding product",
        },
        show_variant_additional_price: {
            default: "Enable additional price for product variants - (Price for each box) - Delivery and pickup",
            0: "Не",
            1: "Да",
        },
        about_restaurant_page_title_in_left: {
            default: "About restaurant page - Move Restaurant name above the logo (If restaurant description is long)",
            0: "Не",
            1: "Да",
        },
        order_button_on_product_variant: {
            default: "Show buttons 'add' and 'comment' in product variants",
            0: "No (only product variant (price & size)",
            1: "Yes - show variant, button 'add' and button 'comment'/'add' (Comment/Add button is visible only if there are active orders for that place)",
            2: "Yes - show variant and button 'add'",
        },
        show_liquid_on_non_liquid_view_product_page: {
            default: "Generate random products for section 'Similar products' in view product page",
            0: "Yes",
            1: "No"
        },
        menu_background_image: {
            default: "Background image for the menu /It is not applied in category page - type Image/"
        },
        logo: {
            default: "Your logo"
        },
        tabl_logo: {
            default: "TabL logo"
        },
        subcategories_design: {
            default: "Subcategories design",
            1: "Images",
            2: "Rows",
            3: "Two columns with images"
        },
        split_header_category_subcategory_on_two_rows: {
            default: "Split category name if there is space between the words",
            0: "No",
            1: "Yes"
        },
        order_email_visible: {
            default: "Shows Email field in order dialog - (except inrestaurant orders)",
            0: "No",
            1: "Yes"
        },
        promocode_visible: {
            default: "Shows Promocode field in order dialog - (except inrestaurant orders)",
            0: "No",
            1: "Yes"
        },
        disable_overtime_orders: {
            default: "Accept orders ONLY in working hours",
            0: "No",
            1: "Yes"
        },
        hide_products_for_ordering_with_token: {
            default: "Activated option for hiding products for ordering /Each product must be marked as 'Only for ordering' also/",
            0: "No",
            1: "Yes"
        },
        ask_for_bill_hidden: {
            default: "Hide bill wanted option button /Only for spot orders/",
            0: "No",
            1: "Yes"
        },
        user_needs_position: {
            default: "Position of button 'Call waiter'",
            header: "Header - menu",
            footer: "Footer"
        },
        hide_order_delivery_pickup_date: {
            default: "Hide option for choosable delivery and pickup time",
            0: "No",
            1: "Yes"
        },
        validate_phone_number_regex: {
            default: "Regex validation number",
        },
        close_order_modal_after_order: {
            default: "Close order modal after order is created",
            0: "No",
            1: "Yes"
        },
        working_hours: {
            default: "Restaurant working time",
        },
        second_working_hours: {
            default: "Working time after the break",
        },
        min_price_free_delivery: {
            default: "Minimum order price for free delivery"
        },
        delivery_price: {
            default: "Delivery price /If order price is less than minimum order price for free delivery - Calculated in the price/"
        },
        show_delivery_price_not_included_text: {
            default: "Show text 'Delivery price is not included' in cart modal",
            0: "No",
            1: "Yes"
        },
        visible_about_page_delivery_prices_text_in_cart: {
            default: "Show text for delivery price in the cart modal. /If the delivery price depends on order weight for example - Click 'Yes' and describe the delivery prices by clicking the link below/",
            0: "No",
            1: "Yes"
        },
        clickable_product_variants: {
            default: "Add 'cart' icon in the product variant",
            0: "No",
            1: "Yes"
        },
        variant_modal_with_comment: {
            default: "Always open new modal (with comment textfield) when the user try to add product in the cart",
            0: "No",
            1: "Yes"
        },
        hide_product_images_without_img: {
            default: "Hide default image for product without image",
            0: "No",
            1: "Yes"
        },
        preorder_percent_discount: {
            default: "Discount for preorder - in percent /Not calculated in the price/"
        },
        ordertype_prices: {
            delivery: "Delivery - prices",
        },
        ordertype_discounts: {
            delivery: "Delivery - Discounts",
            pickup: "Pickup - Discounts",
            spot: "Spot - Discounts",
        },
        menu_header_background: {
            default: 'Background header'
        },
        menu_header_icons_color: {
            default: 'Color header buttons'
        },
        menu_call_button_background: {
            default: 'Background call button'
        },
        menu_call_button_color: {
            default: 'Color call button'
        },
        menu_top_left_dialpad_button_background: {
            default: 'Background menu (top left) buttons'
        },
        menu_top_left_dialpad_button_color: {
            default: 'Color menu (top left) buttons'
        },
        menu_product_addtocart_button_background: {
            default: 'Background Add to cart button - List products page'
        },
        menu_product_addtocart_button_color: {
            default: 'Text color Add to cart button - List products page'
        },
        menu_product_viewpage_addtocart_button_background: {
            default: 'Background Add to cart button - View products page'
        },
        menu_product_viewpage_addtocart_button_color: {
            default: 'Text color Add to cart button - View products page'
        },
        menu_dialogs_background: {
            default: 'Background Dialogs/Modals'
        },
        menu_dialogs_colors: {
            default: 'Text color Dialogs/Modals'
        },
        menu_underline_colors: {
            default: 'Underline color (under categories and under menu buttons (top left))'
        },
        bigger_logo: {
            default: 'Bigger logo'
        },
        product_theme: {
            default: 'Product theme',
            0: "Bar art",
            1: "Bar light",
            2: "Restaurant art",
            3: "Restaurant light",
            4: "Hotel art",
            5: "Hotel light",
            6: "Hotel new"
        },
        admin_notify_on_new_order: {
            default: 'Sound notification for new order',
            1: 'Mark order as read when click on button "New"',
            2: 'Mark order as read when change its status'
        },
        allow_auto_print_orders: {
            default: "Auto print new orders",
            0: 'No',
            1: 'Yes',
        },
        SMS_CLIENT_API_KEY: {
            default: "API KEY FOR CLICKATELL",
            0: 'q8ppKOM_Sy-gydxpYqKXvw== /Ga - 55, Bulg - 54/',
            1: 'OXCQWa2cRrGHi0JKi_-SWA== /Re - 19, Fo - 21, Flo - 47/',
            2: 'BhOHhBm2RkG7FPmfeaHcrA== /SYSTEM PHONES/'
        },
        delivery_radiuses: "Delivery - radiuses",
        delivery_shapes: "Delivery shapes"
    },
    settings_categories: {
        Languages: "Languages and localization",
        Products: "Products",
        Images: "Images",
        CategoriesAndSubcategories: "Categories and subcategories",
        Orders: "Orders",
        General: "General",
        OrdersDelivery: "Orders - Delivery",
        OrdersPreorder: "Orders - Pickup",
        Layout: "Layout",
        Others: "Others",
        Promotions: "Promotions",
        Hotel: "Hotel",
        Banners: "Banners",
        Colors: "Colors",
        Themes: "Themes",
        OrdersPrint: "Orders printer",
        AdminSettings: "Administration",
        current_warehouse_system: "Current warehouse system",
        current_delivery_system: "Current delivery system",
        current_payment_provider: "Online payments",
    },
    users: {
        add: "Add user",
        edit: "Edit user",
        email: "Email",
        password: "Password",
        date_of_registration: "Registration date",
        registered_users: "Registered users",
        fullname: "Full name",
        address: "Address",
        phone: "Phone",
    },
    dashboard: {
        orders: "Orders",
        online_customers: "Online clients",
        monthly_turnover: "Monthly turnover",
        happy_customers: "Happy customers",
        totalAndTodayOrders: "Total orders: {total}, orders today: {today}",
    },
    statistics: {
        users_by_date_count: "Unique users by date",
        page: "Page",
        avg_time_spent: "Avarage time",
        avg_time_spent_per_page: "Avarage time spent on each page",
        total_unique_users: "Total unique users count",
        orders_count: "Orders count",
        total_amount: "Total amount",
        avg_amount: "Avg",
        turnover_by_date: "Turnover by date",
        orders_count_by_date: "Orders count by date",
        most_ordered_product_variants: "Most ordered product variants",
        times_ordered: "Times ordered",
        product_name: "Product",
        top_visited_pages: "Top visited pages and time spent on them"

    },
    roles: {
        SUPER_ADMIN: "Super Admin",
        CLIENT_ADMIN: "All places Admin",
        SALES_ADMIN: "Sales manager",
        PLACE_ADMIN: "One place Admin",
        WAITER: "Waiter",
        PDF_MENU_ADMIN: "PDF menu admin",
        RASPBERRY_ADMIN: "Raspberry",
        USER: "User"
    },
    clients: {
        title: "Clients",
        add: "Add client",
        edit: "Edit client",
        delete: {
            title: "Delete client",
            confirm: "Are you sure you want to delete "
        },
        contract_start_date: "Contract start date",
        trial_start_date: "Trial start date",
        trial_end_date: "Trial end date",
        monthly_fee: "Monthly fee",
        address: "Address",
        additional_info: "Additional info",
        owner_name: "Owner's name",
    },
    regions: {
        title: "Regions",
        add: "Add region",
        edit: "Edit region",
        delete: {
            title: "Delete region",
            confirm: "Are you sure you want to delete the region "
        },
    },
    login: {
        title: "Login",
        email: "Email",
        password: "Password",
        login: "Login"
    },
    feedback: {
        questions: {
            title: "Feedback - questions",
            add: "Add question",
            edit: "Edit question",
            question: "Question",
            question_type: "Question type",
            question_types: {
                'stars': "Stars question",
                'text': "Text question"
            }
        },
        answers: {
            title: "Feedback - answers",
        }
    },
    invoices: {
        year: "Year",
        month: "Month",
        title: "Фактури",
        company_name: "Company name",
        company_eik: "EIK",
        company_vat_registered: "VAT Registered",
        company_city: "Company city",
        company_address: "Company address",
        company_person: "Company MOL (owner)",
        company_email: "Email",
        download: 'Download',
        price: "Price",
        price_with_words: "Price with words",
        date: "Date",
        service_name: "Услуга",
        service_price: "Цена на услуга",
        number: "Invoice №",
        add: "New invoice",
        invoice_original: "Original",
        invoice_copy: "Copy",
        status: "Status",
        new: 'New',
        sent: 'Sent',
        paid: 'Paid',
        canceled: 'Canceled',
        remind_on: 'Remind on',
        sort_by_date: 'Sort by date',
        month: "Month",
        place: "Place",
        partly_payment: 'Partly payment'
    },
    proforms: {
        title: "Проформи",
        company_name: "Company name",
        company_eik: "EIK",
        company_vat_registered: "VAT Registered",
        company_city: "Company city",
        company_address: "Company address",
        company_person: "Company MOL (owner)",
        company_email: "Email",
        download: 'Download',
        price: "Price",
        price_with_words: "Price with words",
        date: "Date",
        service_name: "Услуга",
        service_price: "Цена на услуга",
        number: "Proform №",
        add: "New Proform",
        invoice_original: "Proform",
        invoice_copy: "Proform",
        status: "Status",
        new: 'New',
        sent: 'Sent',
        paid: 'Paid',
        remind_on: 'Remind on',
        sort_by_date: 'Sort by date',
        partly_payment: 'Partly payment'
    },
    bookings: {
        title: "Bookings",
        duration: "Duration",
        service: "Service",
        statuses: {
            unconfirmed: "Unconfirmed",
            confirmed: "Confirmed",
            canceled: "Canceled",
        },
        start_time: "From",
        end_time: "Until",
        rooms: "Rooms",
        add_room: "Add room",
        edit_room: "Edit room"

    },
    table_groups: {
        title: "Table groups",
        min_people_count: "Min. people",
        max_people_count: "Max people",
        tables: 'Tables in the group'

    },
    table_regions: {
        title: "Table regions",
        tables: 'Tables in the region',
        add: 'Add region',
        edit: "Edit region"
    },
    restaurant_halls: {
        title: "Restaurant halls",
        tables: 'Tables in the hall',
        add: "Add hall",
        edit: "Edit hall"
    },
    reservations: {
        title: "Reservations",
        statuses: {
            unconfirmed: "Unconfirmed",
            confirmed: "Confirmed",
            canceled: "Canceled",
        },
        without_table: "Без Маса",
        start_time: "From",
        end_time: "Until",
        all: "All",
        new: "New",
        confirmed: "Confirmed",
        canceled: "Canceled",
        date: "Date and time",
        people_count: "People count",
        restaurant_hall: "Restaurant hall",
        tables: "Tables",
        people: "people",
    },
    ordertypes: {
        delivery: "Delivery",
        preorder: "Preorder",
        spot: "Spot",
        spot_browse: "Spot browse",
        tablet_mode: "Tablet mode",
        room: "Room",
    },
    promocode: {
        ignore_other_discounts: 'Ignore other discounts',
        never_become_used: 'Permanent (Can be used multiple times - doesnt take into account if it is used)',
        never_become_used_short: 'Permanent'
    },
    general_promotions: {
        name: "Name",
        content: 'Content',
        active_from: "Active from",
        active_to: "Active to",
    },
    registered_user_discount: {
        title: "Registered users discounts",
        description: "When activated, registered users receive a discount on every X order. Example: on every 5th order, the user gets 5 BGN discount off his bill",
        how_it_works: "How it works?"
    },
    delivery_radiuses: {
        radius_start: "Radius start (m.)",
        radius_end: "Radius end (m.)",
        min_order_price: "Min. order price",
        delivery_price: "Delivery price for radius",
        required_order_price_for_free_delivery: "Order price for free delivery",
        import_shape_map: "Import shape map",
        import_shape_map_info: "Paste lng, lat pairs divided by a new line in the following format xx.xxxxx, yy.yyyyy...   Delivery price is determined according to the radiuses below depending on the distance from the place",
        shape_map_imported_text: "Delivery map is imported and in use. If you wish to import a new one, paste the coordinate coupes in the field below",
        delete_shape_map: "Delete shape map",
        shape_map_not_imported_text: "Shape map not imported",

    },
    delivery_shapes: {
        title: "Delivery - Maps",
        add: "Add map",
        edit: "Edit delivery shape",
        min_order_price: "Min order price",
        delivery_price: "Delivery price",
        required_order_price_for_free_delivery: "Required order price for free delivery (empty in order not to be applied)",
        coords: "Coordinates (lat, lng)",
        priority_info: "In case of 2 overlapping maps, the data will be taken from the one with lower priority (1 > 2)",
        check_if_address_in_polygon: "Enter address and check if it is in delivery map",
        check_address: "Check address",
        map: "Map - shape the delivery map according to your needs",

    },
    place_images: {
        title: "Gallery",
        add: "Add image"
    },
    warehouse_systems: {
        warehouse_system: "Warehouse system",
        sync_categories: "Sync categories",
        sync_products: "Sync продукти",
        sync_order_statuses: "Sync статуси на поръчка",
        sync_order_delivery_channels: "Sync канали за доставка",
        sync_order_payment_types: "Sync платежни методи",
        resend_order: "Resend ro ",
        resend_order_are_you_sure: "Are you sure you want to send the order to the warehouse system again?"
    },
    delivery_systems: {
        delivery_system: "Delivery system",
        sync_cities_and_regions: "Sync cities and delivery regions",
        resend_order: "Resend"
    },
    payment_providers: {
        payment_provider: "Payment provider",
        choose_payment_provider: "Choose payment provider",
    },
    accumulated_order_amount_setting: {
        title: "Accumulated order amount setting",
        description: "A percentage of each order is set, which will be accumulated as an amount in the user's account. Example: An order is placed for 50 BGN. 10% is set. An amount of 5 BGN will be accumulated in the user's account, which he can use for future orders.",
        percent: "Percent",
        used_discount: "Used discount",
        orders_list_description: "Accumulated amount in account discount"
    }
}