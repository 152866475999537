import React, { Component } from 'react';
import Utils from '../../../utils/Utils';
import { API_URL } from './../../../constants'


class ListRegionsPage extends Component {
    constructor(props) {
        super(props);
        this.state = {
            search_name: '',
            search_active: '',
            orderData: null, // To store the fetched order data
        };
    }

    componentDidMount() {
        this.fetchOrderData();
    }

    fetchOrderData = async () => {
        try {
            // Extract the orderId from the URL
            const url = window.location.href; // Get the full URL
            const urlSegments = url.split('/'); // Split by '/'
            const orderId = urlSegments[urlSegments.length - 1]; // Get the last segment as orderId

            // Make the fetch request with the admin header
            const response = await fetch(`${API_URL}orders/courier/${orderId}`, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    'admin': '1', // Add the admin header
                },
            });

            if (!response.ok) {
                throw new Error('Failed to fetch order data');
            }

            const data = await response.json();
            this.setState({ orderData: data });
        } catch (error) {
            console.error('Error fetching order data:', error);
        }
    };

    handleTakeOrder = async () => {
        const { orderData } = this.state;
        const courierName = localStorage.getItem('tabl_courier_name') || this.state.search_name;

        if (!courierName) {
            alert('Моля въведете име преди да приемете поръчката');
            return;
        }

        try {
            const response = await fetch(`${API_URL}orders/attach_courier_into_order/${orderData.uuid}`, {
                method: 'PUT',
                headers: {
                    'Content-Type': 'application/json',
                    'admin': '1', // Add the admin header
                },
                body: JSON.stringify({ courierName }),
            });

            if (!response.ok) {
                throw new Error('Failed to update order with courier name');
            }

            const result = await response.json();
            alert(result.message || 'Поръчката е успешно приета от вас');
            this.fetchOrderData(); // Refresh order data
        } catch (error) {
            console.error('Error updating order:', error);
            alert('Възникна грешка при приемането на поръчката, моля опитайте пак');
        }
    };


    onChange = (e) => {
        const { name, value } = e.target;
        this.setState({ [name]: value });
        localStorage.setItem('tabl_courier_name', value);
    };

    render() {
        const { orderData } = this.state;
        const courierName = localStorage.getItem('tabl_courier_name');

        let deliveryTime = orderData && orderData.delivery_time ? new Date(orderData.delivery_time) : new Date();
        // let deliveryTime = this.props.order.ordertypeId == 2 ? new Date(this.props.order.delivery_time) : new Date(this.props.order.pickup_time);

        deliveryTime = Utils.appendLeadingZeroes(deliveryTime.getDate()) + '.' + Utils.appendLeadingZeroes(parseInt(parseInt(deliveryTime.getMonth()) + 1)) + ' в ' + Utils.appendLeadingZeroes(deliveryTime.getHours()) + ':' + Utils.appendLeadingZeroes(deliveryTime.getMinutes())



        // +
        //     (
        // Utils.getSettingsValue(this.props.settings, 'enable_showing_diapasone_in_times_for_delivery_or_pickup_in_order_modal') == 1 ?
        //     ' - ' + this.props.addMinutesToTime(deliveryTime.getHours() + ':' + deliveryTime.getMinutes(), Utils.getSettingsValue(this.props.settings, 'minutes_margin_working_hours_choose_date_for_pickup_or_delivery_in_order_modal'))
        //     :
        //     ''
        // )



        return (
            <main className="main">
                {/* Breadcrumb */}
                <ol className="breadcrumb">
                    <li className="breadcrumb-item">СИСТЕМА ЗА КУРИЕРИ - {courierName}</li>
                </ol>

                <div className="container-fluid">
                    <div className="animated fadeIn">
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="card">
                                    <div className="card-body">
                                        <div className="row">
                                            {/* Courier Name Input */}
                                            <div className="col-sm-12">
                                                <div className="form-group">
                                                    <label htmlFor="name">Вашето Име:</label>
                                                    <input
                                                        className="form-control"
                                                        id="name"
                                                        type="text"
                                                        placeholder="Enter Courier Name"
                                                        name="search_name"
                                                        value={this.state.search_name || courierName}
                                                        onChange={this.onChange}
                                                    />
                                                </div>
                                            </div>




                                            {/* Order Details */}
                                            {orderData && courierName ?
                                                <>
                                                    {orderData.courier ?
                                                        <>
                                                            <div className="col-sm-12">
                                                                <div className="form-group">
                                                                    Тази поръчка вече е асоциирана към куриер: {orderData.courier}
                                                                </div>
                                                            </div>



                                                            <div className="col-sm-12">
                                                                <div className="form-group">
                                                                    <b><label>Поръчка N:</label></b>
                                                                    <p>{orderData.id || '-'} / {orderData.place_order_number || '-'}</p>
                                                                </div>
                                                            </div>
                                                            <div className="col-sm-12">
                                                                <div className="form-group">
                                                                    <b><label>Клиент:</label></b>
                                                                    <p>{orderData.client_name || '-'}</p>
                                                                </div>
                                                            </div>
                                                            <div className="col-sm-12">
                                                                <div className="form-group">
                                                                    <b><label>Тел.:</label></b>
                                                                    <p>{orderData.client_phone || '-'}</p>

                                                                    <a
                                                                        href={'tel:' + orderData.client_phone}
                                                                        // className="btn btn-primary"
                                                                        style={{
                                                                            color: '#23282c',
                                                                            background: '#ccc',
                                                                            padding: 5,
                                                                            borderRadius: 5
                                                                        }}
                                                                    >
                                                                        ОБАДИ СЕ
                                                                    </a>




                                                                </div>
                                                            </div>
                                                            <div className="col-sm-12">
                                                                <div className="form-group">
                                                                    <b><label>Адрес:</label></b>
                                                                    <p>{orderData.client_address || '-'}</p>

                                                                    {orderData.street_number ? ' № ' + orderData.street_number : null}
                                                                    {orderData.ap_number ? ' Ап. ' + orderData.ap_number : ''}
                                                                    {orderData.floor_number ? ' Ет. ' + orderData.floor_number : ''}
                                                                    {orderData.bell_number ? ' Зв. ' + orderData.bell_number : ''}
                                                                    {orderData.entrance_number ? ' Вх. ' + orderData.entrance_number : ''}

                                                                    &nbsp;

                                                                    <a
                                                                        href={"https://www.google.com/maps/dir/?api=1&destination=" +
                                                                            (orderData.delivery_address_lat && orderData.delivery_address_lng ? (orderData.delivery_address_lat + ',' + orderData.delivery_address_lng) :
                                                                                (orderData.client_address && orderData.client_address.indexOf('/') !== -1 ? (orderData.client_address.split("/")[0] + '' + (orderData.street_number ? ' № ' + orderData.street_number : '')) : '')
                                                                            )
                                                                        }
                                                                        target="_blank"
                                                                        title={"Google Карта с навигация до адреса"}
                                                                        style={{
                                                                            color: '#23282c',
                                                                            background: '#ccc',
                                                                            padding: 5,
                                                                            borderRadius: 5
                                                                        }}>
                                                                        НАВИГАЦИЯ
                                                                    </a>



                                                                </div>
                                                            </div>



                                                            <div className="col-sm-12">
                                                                <div className="form-group">
                                                                    <b><label>Коментар:</label></b>
                                                                    <p>{orderData.order_text_comment || '-'}</p>
                                                                </div>
                                                            </div>

                                                            <div className="col-sm-12">
                                                                <div className="form-group">
                                                                    <b><label>Email:</label></b>
                                                                    <p>{orderData.client_email || '-'}</p>
                                                                </div>
                                                            </div>

                                                            {/* <div className="col-sm-12">
                                                        <div className="form-group">
                                                            <b><label>Час на взимане от ресторанта:</label></b>
                                                            <p>{orderData.client_address || '-'}</p>
                                                        </div>
                                                    </div> */}

                                                            <div className="col-sm-12">
                                                                <div className="form-group">
                                                                    <b><label>Брой прибори:</label></b>
                                                                    <p>{orderData.count_cutlery || '-'}</p>
                                                                </div>
                                                            </div>

                                                            <div className="col-sm-12">
                                                                <div className="form-group">
                                                                    <b><label>Час на доставка:</label></b>
                                                                    {/* <p>{orderData.delivery_time || '-'}</p> */}
                                                                    <p>{orderData.exact_time_selected ? deliveryTime : 'възможно най-скоро'}</p>

                                                                    <b><i className={"badge badge-dark"}>{orderData.exact_time_selected && !Utils.checkDatesInSameDayAndHour(orderData.delivery_time || orderData.pickup_time, orderData.createdAt) ? 'БЪДЕЩА ПОРЪЧКА' : ''}</i></b>

                                                                </div>
                                                            </div>


                                                            {/* <div className="col-sm-12">
                                                        <div className="form-group">
                                                            <b><label>Тип на поръчка:</label></b>
                                                            <p>{orderData.ordertypeId || '-'}</p>
                                                        </div>
                                                    </div>  */}
                                                            <div className="col-sm-12">
                                                                <div className="form-group">
                                                                    <b><label>ОБЩО:</label></b>
                                                                    <p>{orderData.total_price || '-'} лв.</p>
                                                                </div>
                                                            </div>
                                                            <div className="col-sm-12">
                                                                <div className="form-group">
                                                                    <b><label>Начин на плащане:</label></b>

                                                                    <p>{orderData.payment_method == 'cash' ? 'В Брой'
                                                                        : orderData.payment_method == 'card' ? 'С Карта'
                                                                            : orderData.payment_method == 'epay' ? 'ОНЛАЙН'
                                                                                : orderData.payment_method == 'online' ? 'ОНЛАЙН'
                                                                                    : '-'}</p>


                                                                    {orderData.payment_method == 'epay'
                                                                        || orderData.payment_method == 'online' ?
                                                                        (orderData.payment_status == 'PAID' ? 'УСПЕШНО ПЛАТЕНА' : 'ПРОБЛЕМ С ОНЛАЙН ПЛАЩАНЕТО - НЕ Е ПЛАТЕНА')
                                                                        :
                                                                        ''
                                                                    }

                                                                </div>
                                                            </div>

                                                        </>
                                                        :
                                                        <>
                                                            <div className="col-sm-12">
                                                                <div className="form-group">
                                                                    <button
                                                                        className="btn btn-primary"
                                                                        onClick={this.handleTakeOrder}
                                                                    >
                                                                        ВЗЕМИ ПОРЪЧКА
                                                                    </button>
                                                                </div>
                                                            </div>


                                                            <div className="col-sm-12">
                                                                <div className="form-group">
                                                                    <b><label>Поръчка N:</label></b>
                                                                    <p>{orderData.id || 'N/A'} / {orderData.place_order_number || 'N/A'}</p>
                                                                </div>
                                                            </div>

                                                            <div className="col-sm-12">
                                                                <div className="form-group">
                                                                    <b><label>Клиент:</label></b>
                                                                    <p>{orderData.client_name || 'N/A'}</p>
                                                                </div>
                                                            </div>


                                                            <div className="col-sm-12">
                                                                <div className="form-group">
                                                                    <b><label>Адрес:</label></b>
                                                                    <p>{orderData.client_address || 'N/A'}</p>

                                                                    {orderData.street_number ? ' № ' + orderData.street_number : null}
                                                                    {orderData.ap_number ? ' Ап. ' + orderData.ap_number : ''}
                                                                    {orderData.floor_number ? ' Ет. ' + orderData.floor_number : ''}
                                                                    {orderData.bell_number ? ' Зв. ' + orderData.bell_number : ''}
                                                                    {orderData.entrance_number ? ' Вх. ' + orderData.entrance_number : ''}

                                                                    &nbsp;

                                                                    <a
                                                                        href={"https://www.google.com/maps/dir/?api=1&destination=" +
                                                                            (orderData.delivery_address_lat && orderData.delivery_address_lng ? (orderData.delivery_address_lat + ',' + orderData.delivery_address_lng) :
                                                                                (orderData.client_address && orderData.client_address.indexOf('/') !== -1 ? (orderData.client_address.split("/")[0] + '' + (orderData.street_number ? ' № ' + orderData.street_number : '')) : '')
                                                                            )
                                                                        }
                                                                        target="_blank"
                                                                        title={"Google Карта с навигация до адреса"}
                                                                        style={{
                                                                            color: '#23282c',
                                                                            background: '#ccc',
                                                                            padding: 5,
                                                                            borderRadius: 5
                                                                        }}>
                                                                        НАВИГАЦИЯ
                                                                    </a>



                                                                </div>
                                                            </div>


                                                        </>
                                                    }




                                                </>
                                                :
                                                <div className="col-sm-12">
                                                    <p>Loading order data...</p>
                                                </div>
                                            }
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </main >
        );
    }
}

export default ListRegionsPage;
