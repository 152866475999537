import React, { useEffect, useState } from 'react'
import { NavLink } from 'react-router-dom';
import { ADMIN_URL } from '../../../constants'
import DeleteModal from '../../common/DeleteModal';
import MaterialDateTimePicker from '../../common/MaterialDateTimePicker';
import bgLocale from "date-fns/locale/bg";
import buildLocalizeFn from 'date-fns/locale/_lib/buildLocalizeFn'
import enLocale from "date-fns/locale/en-GB";
import { DateTimePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns'; // choose your lib

const ReservationForm = (props) => {
    console.log('props', props);
    const actionText = props.isAdding ? 'Добави' : 'Запази';

    const [locale, setLocale] = useState("bg");

    // Same as - node_modules\date-fns\locale\bg\_lib\localize\index.js
    var dayValues = {
        narrow: ['нд', 'пн', 'вт', 'ср', 'чт', 'пт', 'сб'],
        short: ['нед', 'пон', 'вто', 'сря', 'чет', 'пет', 'съб'],
        wide: ['неделя', 'понеделник', 'вторник', 'сряда', 'четвъртък', 'петък', 'събота']
    };

    // Changing day names
    bgLocale.localize.day = buildLocalizeFn({
        values: dayValues,
        defaultWidth: 'short',
    });

    const localeMap = {
        bg: bgLocale,
        en: enLocale,
    };


    let selectedPlace, selectedRoom;
    let placesOptions = Object.values(props.allPlaces).map((place) => {
        if (props.reservation.place_id == place.id) {
            selectedPlace = place.id;
        }
        // if (place.active == 1) {
        return <option key={place.id} value={place.id}>{place.name}</option>
        // }
    });

    let roomsOptions = Object.values(props.rooms).map((room) => {
        if (props.reservation.room_id == room.id) {
            selectedRoom = room.id;
        }
        // if (place.active == 1) {
        return <option key={room.id} value={room.id}>{room.name}</option>
        // }
    });

    // Set select value from the state (if is set)
    // let selectedDate = (props.reservation && props.reservation.date_time_from) ? props.reservation.date_time_from : new Date();

    let selectedDate;

    if (props.reservation && props.reservation.date_time_from) {
        selectedDate = props.reservation.date_time_from;
    } else {

        if (props.dateTimeForRes) {
            selectedDate = new Date(Number(props.dateTimeForRes));
        } else {
            const now = new Date();
            const minutes = now.getMinutes();
            const increment = 15;
            const nextQuarter = increment - (minutes % increment);
            const nextQuarterDate = new Date(now.getTime() + nextQuarter * 60000);

            // Adjust hours if the next quarter falls into the next hour
            if (nextQuarterDate.getMinutes() === 0 && nextQuarter !== 15) {
                nextQuarterDate.setHours(nextQuarterDate.getHours());
            }

            selectedDate = nextQuarterDate;
        }

        const data = {};
        data.target = {}
        data.target.value = selectedDate
        data.target.name = 'date_time_from'

        props.onChange(data)
    }

    console.log('selectedDate', selectedDate);
    let currentPlace = props.allPlaces[props.reservation.place_id] ? props.allPlaces[props.reservation.place_id] : [];
    let todayIsDisabled = disableForbiddenDates(selectedDate);

    useEffect(() => {
        if (props.passedTableId) {
            props.addTables(props.passedTableId);
        }
    }, []);

    const handleDateChange = (calendarDate) => {
        console.log('calendarDate', calendarDate)
        console.log('calendarDate.getMinutes()', calendarDate.getMinutes())
        let data = [];
        data.target = [];
        data.target.name = 'date_time_from';
        if (calendarDate.getMinutes() != 0
            && calendarDate.getMinutes() != 15
            && calendarDate.getMinutes() != 30
            && calendarDate.getMinutes() != 45
        ) {
            calendarDate.setMinutes(0);
        }
        data.target.value = calendarDate;

        props.onChange(data);
    }

    // Functions
    function disableForbiddenDates(date) {
        date = new Date(date)
        if (currentPlace.forbidden_reservations) {
            for (let i = 0; i < Object.keys(currentPlace.forbidden_reservations).length; i++) {
                let forbiddenDates = currentPlace.forbidden_reservations[i];
                let forbidenStartDate = new Date(forbiddenDates.forbidden_start_time * 1000)
                let forbidenEndDate = new Date(forbiddenDates.forbidden_end_time * 1000);
                let temp_forbidenStartDate = new Date(parseInt(forbidenStartDate.getFullYear()) + '-' + parseInt(forbidenStartDate.getMonth() + 1) + '-' + parseInt(forbidenStartDate.getDate()) + ' 00:00:00');
                let temp_forbidenEndDate = new Date(parseInt(forbidenEndDate.getFullYear()) + '-' + parseInt(forbidenEndDate.getMonth() + 1) + '-' + parseInt(forbidenEndDate.getDate()) + ' 00:00:00');
                let temp_date = new Date(parseInt(date.getFullYear()) + '-' + parseInt(date.getMonth() + 1) + '-' + parseInt(date.getDate()) + ' 00:00:00');

                // If date is forbidden
                if ((parseInt(temp_forbidenStartDate.getTime()) <= parseInt(temp_date.getTime()) && parseInt(temp_date.getTime()) <= parseInt(temp_forbidenEndDate.getTime())) && parseInt(forbiddenDates.only_by_phone) !== 1) {
                    return true; //Disable booking for that day
                }
            }
        }
        return false;
    }


    const tableSelects = props.tables.map(table => {
        let selectedTable;

        let options = Object.values(props.allTables).map((t) => {
            if (table.value == t.id) {
                selectedTable = t.id;
            }
            return <option key={t.id} value={t.id}>
                {/* {t.number} - {props.allRooms[t.room_id].name} - мин. бр. хора:{t.min_people_count} - макс. бр. хора:{t.max_people_count} */}
                {t.table_num} - мин. бр. хора:{t.reservation_min_people} - макс. бр. хора:{t.reservation_max_people}
            </option>
        });

        return (
            <div key={table.key} className="row">
                <div className="col-md-11">
                    <select name={table.key} className="form-control" onChange={props.onTableSelectChange} value={selectedTable} disabled>
                        <option key={0} value={0}>-</option>
                        {options}
                    </select>
                </div>
                <div className="col-md-1">
                    <button onClick={() => props.removeTable(table.key)} type="button" className="close" aria-label="Close" disabled>
                        <span aria-hidden="true">&times;</span>
                    </button>
                </div>
            </div>
        )

    })
    return (
        <form onSubmit={props.onSubmit}>
            <div className="row">
                <div className="col-md-12">
                    <div className="form-group">
                        <label htmlFor="name">Дата/час</label>
                        {/* <input className={`form-control`} id="name" type="datetime-local" placeholder="Дата/час" 
                        name="date_time_from" onChange={props.onReservationChange} value={props.reservation ? props.reservation.date_time_from:""}/> */}

                        {/* <MaterialDateTimePicker/> */}

                        <MuiPickersUtilsProvider utils={DateFnsUtils} className="w-100" locale={localeMap[locale]}>
                            <DateTimePicker
                                autoOk
                                ampm={false}
                                value={selectedDate}
                                onChange={handleDateChange}
                                className="w-100"
                                shouldDisableDate={disableForbiddenDates}
                                format="dd/MM/yyyy HH:mm"
                                minutesStep="15"
                            />
                        </MuiPickersUtilsProvider>

                    </div>
                </div>
            </div>


            <div className="row">
                <div className="form-group col-sm-4">
                    <div className="form-group">
                        <label htmlFor="people_count">Брой хора</label>
                        <input className={`form-control`} id="people_count" type="number" placeholder="Бр. хора" min={1}
                            name="people_count" onChange={props.onReservationChange} value={props.reservation ? props.reservation.people_count : ""} />
                    </div>
                </div>

                <div className="form-group col-sm-4">
                    <div className="form-group">
                        <label htmlFor="client_name">Име</label>
                        <input className={`form-control`} id="client_name" type="text" placeholder="Име"
                            name="client_name" onChange={props.onReservationChange} value={props.reservation ? props.reservation.client_name : ""} />
                    </div>
                </div>

                <div className="form-group col-sm-4">
                    <div className="form-group">
                        <label htmlFor="client_phone">Телефон</label>
                        <input className={`form-control`} id="client_phone" type="text" placeholder="Телефон"
                            name="client_phone" onChange={props.onReservationChange} value={props.reservation ? props.reservation.client_phone : ""} />
                    </div>
                </div>


                {/*  */}


                <div className="form-group col-sm-4">
                    <div className="form-group">
                        <label htmlFor="paid_ammount">Капаро</label>
                        <input className={`form-control`} id="paid_ammount" type="text"
                            name="paid_ammount"
                            onChange={props.onReservationChange} value={props.reservation ? props.reservation.paid_ammount : ""}
                        />
                    </div>
                </div>


                <div className="form-group col-sm-4">
                    <div className="form-group">
                        <label htmlFor="menu_included">Меню</label>
                        {/* <input type="checkbox" className="switch-input" name="boldTitle" */}
                        {/* // onChange={(e) => this.setState({ boldTitle: !this.state.boldTitle })} checked={this.state.boldTitle == true} 
                        // /> */}

                        <div>
                            <label className="switch switch-label switch-pill switch-outline-primary-alt">
                                <input className="switch-input" type="checkbox" name="menu_included"
                                    onChange={props.onChangeCheckbox} checked={props.reservation.menu_included}
                                />
                                <span className="switch-slider"
                                    data-checked={'Да'} data-unchecked={'Не'}
                                ></span>
                            </label>
                        </div>

                        {/* <input className={`form-control`} id="client_phone" type="text" placeholder="Телефон"
                            name="client_phone" onChange={props.onReservationChange} value={props.reservation ? props.reservation.client_phone : ""} /> */}
                    </div>
                </div>

                <div className="form-group col-sm-4">
                    <div className="form-group">
                        <label htmlFor="table_choosed">Избрана маса</label>
                        {/* <input type="checkbox" className="switch-input" name="boldTitle" */}
                        {/* // onChange={(e) => this.setState({ boldTitle: !this.state.boldTitle })} checked={this.state.boldTitle == true} 
                        // /> */}

                        <div>
                            <label className="switch switch-label switch-pill switch-outline-primary-alt">
                                <input className="switch-input" type="checkbox" name="table_choosed"
                                    onChange={props.onChangeCheckbox} checked={props.reservation.table_choosed}
                                />
                                <span className="switch-slider"
                                    data-checked={'Да'} data-unchecked={'Не'}
                                ></span>
                            </label>
                        </div>

                        {/* <input className={`form-control`} id="client_phone" type="text" placeholder="Телефон"
                            name="client_phone" onChange={props.onReservationChange} value={props.reservation ? props.reservation.client_phone : ""} /> */}
                    </div>
                </div>


                <div className="form-group col-sm-4">
                    <div className="form-group">
                        <label htmlFor="description">Описание</label>
                        <textarea className={`form-control`} id="client_phone" type="text" 
                            name="description" onChange={props.onReservationChange} value={props.reservation ? props.reservation.description : ""} >
                        </textarea>
                    </div>
                </div>

            </div>



            {/* <hr />


            <div className="row">
                <div className="form-group col-sm-4">
                    <label htmlFor="category_id">Обект</label>
                    <select className="form-control" id="place_id" name="place_id" onChange={props.onPlaceChange} value={selectedPlace}>
                        {placesOptions}
                    </select>
                </div>

                <div className="form-group col-sm-4">
                    <div className="form-group">
                        <label htmlFor="category_id">Зала</label>
                        <select className="form-control" id="room_id" name="room_id" onChange={props.onReservationChange} value={selectedRoom}>
                            {roomsOptions}
                        </select>
                    </div>
                </div>

            </div> */}
            {/* <hr/> */}

            {/* <div className="row">
                <div className="form-group col-sm-4">
                    <div className="form-group">
                        <label htmlFor="name">Статус</label>
                        <input className={`form-control`} id="status" type="text" placeholder="" 
                        name="status" onChange={props.onChange} value={props.reservation ? props.reservation.status:""}/>
                    </div>
                </div>
            </div> */}

            <hr />

            <div className="row">
                <div className="form-group col-sm-4">
                    <p>Ако не добавите маси, ще бъдат автоматично избрани такива.</p>
                </div>
                <div className="form-group col-sm-2">
                    <button className="btn btn-primary" type="button" onClick={props.addTable} disabled>Добави маса</button>
                </div>
                <div className="form-group col-sm-4">
                    <ul>
                        {tableSelects}
                    </ul>
                </div>
            </div>

            <hr />

            <div className="form-group form-actions">
                {/* {props.isAdding == '1' ? '' : <button className="btn btn-danger mright10" type="button" data-toggle="modal" data-target={"#dangerModal" + props.reservation.id} >Изтрий</button>} */}

                {/* <DeleteModal
                    id={props.reservation.id}
                    name={props.reservation.name}
                    type={"зала"}
                    removeFunction={props.removeRoom}
                /> */}

                <NavLink to={ADMIN_URL + 'reservations'}>
                    <button className="btn btn-secondary" type="button">
                        Откажи
                    </button>
                </NavLink>

                <button className="fright btn btn-primary" type="submit">{actionText}</button>

            </div>

        </form>
    )
}

export default ReservationForm;