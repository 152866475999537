import React, { Component } from 'react'
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux'
// import CircularProgress from '@mui/material/CircularProgress';
import moment from 'moment';

import { listTables, removeTable, editTable } from '../../../actions/tablesActions';
import { listTableRegions } from '../../table_regions/actions/tableRegions.actions.js';
import { listRestaurantHalls } from '../../table_halls/actions/restaurantHalls.actions.js';

import { editReservation, setCurrentlyEditedReservation, addReservation, markReservationsAsNotified } from '../../../reservations/reservations/actions/reservations.actions.js';

// admin_app\src\reservations\reservations\actions\reservations.actions.js
// admin_app\src\components\reservations\actions\reservations.actions.js

import { listReservations } from '../actions/reservations.actions';
import ListReservationsItem from './ListReservationsItem';
import ListReservationsItemBox from './ListReservationsItemBox';
import { NavLink } from 'react-router-dom';
import { ADMIN_URL, ITEMS_PER_PAGE, ITEMS_PAGE_RANGE_DISPLAYED } from '../../../constants.js'
import Pagination from "react-js-pagination";
import { push } from 'react-router-redux';
import enum_admin_notify_on_new_order from './../../common/enums/admin_notify_on_new_order.json';
import MessagesContainer from '../../messages/MessagesContainer';
import { translations } from '../../common/translations';
import Filter from './X_Filter.js';
import soundfileReservation from './../../orders/sounds/booking/1.wav'
import Sound from 'react-sound';
import { setSoundActivation, changeOrdersStyleType } from '../../../actions/adminActions';
import SoundActivatedDialog from './../../common/SoundActivatedDialog';
import Auth from '../../../utils/Auth';
import Utils from '../../../utils/Utils';
import { Stage, Layer, Star, Text, Rect, Circle, Group } from 'react-konva';
import bgLocale from "date-fns/locale/bg";
import buildLocalizeFn from 'date-fns/locale/_lib/buildLocalizeFn'
import enLocale from "date-fns/locale/en-GB";
import { DatePicker, TimePicker, DateTimePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
// import { DateTime } from 'material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns'; // choose your lib
import { Box, Button, CircularProgress } from '@material-ui/core';
import EachReservationTableBox from './EachReservationTableBox.js';
import NewReservationDialog from '../../common/NewReservationDialog.js';
import SoundReservation from '../../orders/sounds/new-order/10.mp3';

// const soundFiles = {
//     '1': import('../../orders/sounds/new-order/1.mp3'),
//     '2': import('../../orders/sounds/new-order/2.mp3'),
//     // Add more sound mappings here
// };

function generateShapes() {
    return [...Array(2)].map((_, i) => ({
        id: i.toString(),
        x: Math.random() * window.innerWidth,
        y: Math.random() * window.innerHeight,
        rotation: Math.random() * 180,
        isDragging: false,
        shape: 'rectangle',
        width: 50, // Default width for rectangle
        height: 50, // Default height for rectangle
    }));
}

const INITIAL_STATE = generateShapes();

class ListReservationsPage extends React.Component {
    constructor(props) {
        super(props);

        const today = new Date();
        const day = String(today.getDate()).padStart(2, '0');
        const month = String(today.getMonth() + 1).padStart(2, '0'); // January is 0!
        const year = today.getFullYear();


        const now = new Date();
        const hours = String(now.getHours()).padStart(2, '0');
        const minutes = String(now.getMinutes()).padStart(2, '0');

        this.activeDefaultLanguage = 'bg'; // TODO add it in db
        this.state = {
            locale: "bg",
            isDragged: false,
            initialTableState: null,
            search_client_name: '',
            search_client_phone: '',
            search_start_time: '',
            search_end_time: '',
            search_status: '',
            timer: '',
            activeTab: 1,
            visibleFilter: false,

            stars: INITIAL_STATE,
            selectedShape: 'square',
            selectedAction: 'none',
            // selectedItem: null,
            selectedItem: false,
            selectedMultipleItems: [],
            tableCoord: [],
            isDraggingItem: false,
            isLoadingTables: true,

            enableDragging: false,
            selectedRestaurantHallToShow: 0,
            visibleMoreSettings: 0,
            reservationsForDate: new Date(),
            reservationsForTime: new Date(),
            isSelectedNow: true,
            reservationsPrepared: false,
            realLoaderLoading: true,
            savingLoaderLoading: true,
            makeFiltering: false,
            tablesWithColors: {
                ['expecting']: {}, // yellow
                ['notarrived']: {}, // blue
                ['bookedForToday']: {}, // gray
                ['confirmed']: {}, // red
            },
            filterListingRes: 1,
            showAllResDescForToday: false,
            reservationModalClosed: false,
            // createEditReservationButtonText: 'Създай Резервация',
            // createEditReservationButtonLink: '',
        };
        this.statuses = [
            'unconfirmed', 'confirmed', 'canceled'
        ]

        // Same as - node_modules\date-fns\locale\bg\_lib\localize\index.js
        var dayValues = {
            narrow: ['нд', 'пн', 'вт', 'ср', 'чт', 'пт', 'сб'],
            short: ['нед', 'пон', 'вто', 'сря', 'чет', 'пет', 'съб'],
            wide: ['неделя', 'понеделник', 'вторник', 'сряда', 'четвъртък', 'петък', 'събота']
        };

        // Changing day names
        bgLocale.localize.day = buildLocalizeFn({
            values: dayValues,
            defaultWidth: 'short',
        });

        this.localeMap = {
            bg: bgLocale,
            en: enLocale,
        };

    }

    componentWillMount() {
        // this.props.listTables();
        this.props.listRestaurantHalls();
        this.props.listTableRegions();
        this.props.listReservations();
    }


    componentWillUnmount() {
        // console.log('UNMOUNTING',);
    }

    componentDidUpdate() {
        ////console.log('DID UPDATE this.state.isLoadingTables', this.state.isLoadingTables);
        ////console.log('DID UPDATE this.props.tables', this.props.tables);


        // console.log('this.props.currentlyEditedReservation', this.props.currentlyEditedReservation);
        if (
            (this.state.realLoaderLoading == true && this.props.items && this.props.items.length > 0)
            ||
            (this.props.currentlyEditedReservation == true && this.props.items && this.props.items.length > 0)
        ) {
            // console.log('IN THE UPDATE-this.props.items.length > 0', this.props.items);
            // console.log('IN THE UPDATE-this.props.items.length > 0', this.props.items.length > 0);
            this.setState({ realLoaderLoading: false });
            // this.prepareReservationData();
            // this.props.listReservations(this.state.search_start_time, this.state.search_end_time, this.state.search_status);
            this.prepareReservationData(new Date(), new Date());
            this.props.setCurrentlyEditedReservation(false);
        }


        // console.log('this.state.isLoadingTables', this.state.isLoadingTables);
        // console.log('TABLES LOADING... ', this.props.tables);

        if (!this.state.isLoadingTables && (this.props.tables && (!Array.isArray(this.props.tables)) && (typeof this.props.tables == 'object') && Object.values(this.props.tables).length > 0)) {
            // console.log('TABLES LOADED ', this.props.tables);

            let filteredTables = {};
            for (const key in this.props.tables) {
                const table = this.props.tables[key];
                if (table.is_for_reservation) {
                    filteredTables[key] = {
                        x: table.x,
                        y: table.y,
                        w: table.w,
                        h: table.h,
                    };
                }
            }

            // const filteredTables = Object.values(this.props.tables)
            //     .filter(table => table.is_for_reservation == true)
            //     .reduce((result, table) => {
            //         result[table.id] = { x: table.x, y: table.y };
            //         return result;
            //     }, {});

            ////console.log('SETTING tableCoord', filteredTables);
            this.setState({ isLoadingTables: true, tableCoord: filteredTables })
        }

        if (this.state.selectedRestaurantHallToShow == 0 && this.props.restaurantHalls && Object.values(this.props.restaurantHalls).length > 0) {
            //console.log('filtering');
            this.filterTablesByRestaurantHallId(Object.values(this.props.restaurantHalls)[0].id)
        }


    }

    async componentDidMount() {
        try {
            await this.props.listTables();
            // console.log('CALLED PREPARE RESERVATIONS',);
            // await this.prepareReservationData();

            // Request is complete; update the state
            let stateToSet = {};

            if (this.state.isLoadingTables) {
                stateToSet.isLoadingTables = false
            }

            if (!this.state.reservationsPrepared) {
                stateToSet.reservationsPrepared = true
            }

            // stateToSet.orderSound = soundFiles['1']

            this.setState(stateToSet)

        } catch (error) {
            // Handle any errors that occurred during the request
            console.error("Error loading tables:", error);
            this.setState({ isLoadingTables: false }); // You might want to handle errors differently
        }
        // this.props.listTables(() => {
        //     // Update the state when tables are loaded
        //     this.setState({ isLoadingTables: false });
        // });

        const setNotificationTimeout = () => {
            if (this.state.isSelectedNow) {
                this.setState({
                    reservationsForDate: new Date(),
                    reservationsForTime: new Date(),
                })
            }

            this.props.listReservations(this.state.search_start_time, this.state.search_end_time, this.state.search_status);
            if (this.state.isSelectedNow) {
                // console.log('CALLED PREPARE RESERVATIONS',);
                this.prepareReservationData(new Date(), new Date());
            } else {
                // console.log('CALLED PREPARE RESERVATIONS',);
                this.prepareReservationData(this.state.reservationsForDate, this.state.reservationsForTime);
            }
            if (!this.state.reservationsPrepared) {
                this.setState({ reservationsPrepared: true })
            }

            if (this.state.realLoaderLoading) {
                this.setState({ realLoaderLoading: false })
            }
            if (this.state.savingLoaderLoading) {
                this.setState({ savingLoaderLoading: false })
            }

        }
        // setNotificationTimeout();
        let timer = setInterval(setNotificationTimeout, 3000);
        this.setState({ timer: timer });
        // this.prepareReservationData();


        // setTimeout(() => {
        //     this.prepareReservationData();
        // }, 1000);

        // setTimeout(() => {
        //     this.prepareReservationData();
        // }, 2000);

        // setTimeout(() => {
        //     this.prepareReservationData();
        // }, 2500);

        // setTimeout(() => {
        //     this.prepareReservationData();
        // }, 3000);

        // setTimeout(() => {
        //     this.prepareReservationData();
        // }, 4000);

        setInterval(() => {
            // this.prepareReservationData();
            this.setState({ reservationModalClosed: false })
        }, 10000);


        // if (this.props.tableRegions && Object.values(this.props.tableRegions)) {
        //     //console.log('this.props.tableRegions', this.props.tableRegions);
        //     this.prepareReservationData();
        //     // this.filterTablesByRestaurantHallId(Object.values(this.props.tableRegions)[0].id)
        // }

    }

    // componentWillReceiveProps(nextProps) {
    //     const sound_notification_new_order = Utils.getSettingsValue(settings, "sound_notification_new_order");
    //     if (sound_notification_new_order && !this.state.orderSound) {
    //         import(`./sounds/new-order/${sound_notification_new_order}.mp3`).then(e => this.setState({ orderSound: e })).catch(err => this.props.addErrorMessages([translations && translations.messages && translations.messages.errors && translations.messages.errors.sound_not_found ? translations.messages.errors.sound_not_found : 'Sound not found']))
    //     }

    // }



    componentWillUnmount() {
        clearInterval(this.state.timer);
    }

    handlePageChange = (page) => {
        this.props.dispatch(push('/admin/reservations?page=' + page))
    }

    onChangeText = (e) => {
        this.setState({ [e.target.name]: e.target.value });
    }

    onChange = (e) => {
        this.setState({ [e.target.name]: e.target.value });
        this.handlePageChange(1);
    }

    onChangeSelectAutocompleteStatus = async (id, newValue) => {
        this.setState({
            savingLoaderLoading: true
        });
        let post = {
            id: id,
            status: newValue.value
        }
        this.props.editReservation(post);
        this.prepareReservationData();

    }

    toggleAllResDescForToday = () => {
        this.setState({
            showAllResDescForToday: !this.state.showAllResDescForToday
        });
    }

    startMovingReservation = (resId) => {
        this.setState({ movingReservationId: resId, selectedMultipleItems: [] })
        // this.state.
    }

    stopMovingReservation = () => {
        const selectedMultipleItems = this.state.selectedMultipleItems;

        let post = {
            id: this.state.movingReservationId,
        }

        let formData = new FormData();

        for (var key in post) {
            formData.append(key, post[key]);
        }
        formData.append('tables', JSON.stringify(selectedMultipleItems))
        // tables: JSON.stringify({ tables: selectedMultipleItems }),

        this.props.editReservation(formData);



        // 
        // this.props.editReservation(post);
        this.prepareReservationData();
        this.setState({ movingReservationId: null, selectedMultipleItems: [] })
    }

    createReservationFromAdmin = (mergedDateTime) => {

        //         currentResName
        // currentResPhone
        // currentResTime
        // currentResPCount
        // currentResPlace


        const post = {
            reservationDate: mergedDateTime,
            date: mergedDateTime.toISOString().split('T')[0],
            time: mergedDateTime.toTimeString().split(' ')[0],
            status: 'confirmed',
            // place_id: 62,
            // room_id: (Auth.getUserPlace() == 359 || Auth.getUserPlace() == 62) ? 1 : this.state.reservation.room_id,
            // room_id: this.props.passedHallId,
            people_count: 1,//this.state.reservation.people_count,
            // restaurantHallId: (Auth.getUserPlace() == 359 || Auth.getUserPlace() == 62) ? 1 : '',
            currentRestaurantHallId: this.state.selectedRestaurantHallToShow,
            place: Auth.getUserPlace(),//this.state.reservation.place_id,
            client_name: 'ГОСТИ',//this.state.reservation.client_name,
            client_phone: '111',//this.state.reservation.client_phone,
            // description: this.state.reservation.description,
            // menu_included: this.state.reservation.menu_included,
            // paid_ammount: this.state.reservation.paid_ammount,
            is_admin: 1,

        }

        post.tabl_reservations_main_tablet = localStorage.getItem('tabl_reservations_main_tablet');


        //         name
        // phone
        // date
        // reservationDate
        // time
        // peopleCount
        // restaurantHallId
        // place

        let formData = new FormData();
        for (var key in post) {
            formData.append(key, post[key]);
        }
        // console.log('formData', formData);
        // console.log('tableIds',tableIds);
        formData.append('tables', JSON.stringify(this.state.selectedMultipleItems))

        // console.log(form_data);return;
        // formData.append('date_time_from', resData.date_time_from)
        // formData.append('people_count', resData.people_count)
        // formData.append('room_id', resData.room_id)
        // formData.append('name', resData.name)
        // formData.append('phone', resData.phone)

        this.props.addReservation(formData);


    }

    onChangeSelectAutocompleteStatusParam = async (id, newValue) => {
        this.setState({
            savingLoaderLoading: true
        });
        let post = {
            id: id,
            status: newValue
        }
        this.props.editReservation(post);
        this.prepareReservationData();

    }


    onChangeSelectAutocomplete = (fieldName, newValue) => {
        this.setState({ [fieldName]: newValue.value })
    }

    onDateChange = (fieldName, date) => {
        this.setState({ [fieldName]: date });
    }

    callFilter = () => {
        // this.props.listReservations(this.state.search_start_time, this.state.search_end_time, this.state.search_status);
    }

    clearFilter = () => {
        this.setState({
            makeFiltering: false,
            search_client_name: '',
            search_client_phone: ''
        })
    }

    addTabletAsMainForReservation = (key, value) => {
        localStorage.setItem(key, value);
        window.location.reload();
    }

    makeFilter = () => {
        this.setState({ makeFiltering: true });
        this.handlePageChange(1)
    }


    setActiveTab = (tabNum) => {
        const { settings } = this.props;

        let reservations = this.props.items;
        ////console.log('reservations', reservations)
        if (this.state.activeTab == 2) {
            reservations = reservations.filter(o => (o.status == "unconfirmed"));
        }

        this.setState({ activeTab: tabNum });

        if (tabNum == 2) {
            if (reservations && reservations.length > 0) {
                // if (Utils.getSettingsValue(settings, 'admin_notify_on_new_order', '1') == enum_admin_notify_on_new_order.UNREAD_ORDERS) {
                this.props.editReservation({ seen: true, placeId: Auth.getUserPlace() })
                // this.updateSeen(this.getSelectedTableIds(), 'seen_order', unreadOrderedItems);
                // }
            }
        }
    }



    handleDragStart = (e, tableId) => {
        ////console.log('handleDragStart', e, tableId);

        ////console.log('thisIN handleDragStart - .state.enableDragging', this.state.enableDragging);
        // if (!this.state.enableDragging) return;
        const id = e.target.id();
        // this.setState({
        //     stars: this.state.stars.map((star) => {
        //         return {
        //             ...star,
        //             isDragging: star.id === id,
        //         };
        //     }),
        // });
        this.setState({ isDraggingItem: tableId });
    };

    handleDragEnd = (e, tableId) => {
        ////console.log('handleDragEnd', e, tableId);
        ////console.log('IN handleDragEnd - this.state.enableDragging', this.state.enableDragging);
        // if (!this.state.enableDragging) return;
        ////console.log('evvv', e);
        let x = Math.round(parseInt(e.target.attrs.x) / 25) * 25 //.split('-')[0]
        let y = Math.round(parseInt(e.target.attrs.y) / 25) * 25 //.split('-')[0]

        if (x < 0) { x = 0 }
        if (y < 0) { y = 0 }

        ////console.log('x', x);
        ////console.log('y', y);

        ////console.log('BEFORE: this.state.tableCoord', this.state.tableCoord);
        const newState = { [tableId]: { x: x, y: y } };
        this.setState((prevstate) => {
            ////console.log('SETTING NEW STATEEEEEEEEEEEEE ', { tableCoord: { ...prevstate.tableCoord, [tableId]: { x: x, y: y } }, isDraggingItem: false });
            return (
                { tableCoord: { ...prevstate.tableCoord, [tableId]: { x: x, y: y } }, isDraggingItem: false }
            )
        })
        ////console.log('AFTER: hist.state.tableCoord - newState', newState);
        // this.setState({
        //     isDragged: true,
        //     stars: this.state.stars.map((star) => {
        //         return {
        //             ...star,
        //             isDragging: false,
        //         };
        //     }),
        // });
        ////console.log('e', e);

        ////console.log('x', x);
        ////console.log('y', y);
        ////console.log('tableId', tableId);
        this.props.editTable(tableId, { x: x, y: y });

    };

    handleShapeChange = (shape) => {
        this.setState({ selectedShape: shape, selectedAction: 'none', drawShape: true });
    };

    handleResize = (increment) => {
        // const tableId = this.state.selectedItem;
        const tableIds = this.state.selectedMultipleItems;

        let tableId;
        let stateForUpdate = {};

        for (let k = 0; k < tableIds.length; k++) {
            tableId = tableIds[k]
            let currWidth = this.state.tableCoord && this.state.tableCoord[tableId] && this.state.tableCoord[tableId].w ? this.state.tableCoord[tableId].w : 100
            let currHeight = this.state.tableCoord && this.state.tableCoord[tableId] && this.state.tableCoord[tableId].h ? this.state.tableCoord[tableId].h : 100

            let newWidth = increment ? (parseInt(currWidth) + 25) : (parseInt(currWidth) - 25);
            let newHeight = increment ? (parseInt(currHeight) + 25) : (parseInt(currHeight) - 25);

            if (!increment && newHeight == 50) {
                newHeight = 75;
            }

            if (increment && currWidth == 50) {
                newHeight = 75;
            }

            if (newWidth > 300 || newWidth < 50 ||
                newHeight > 300 || newHeight < 50) {
                return
            }

            // const newWidth = parseInt(currWidth) + 25;
            // const newHeight = parseInt(currHeight) + 25;

            stateForUpdate[tableId] = { w: newWidth, h: newHeight, x: this.state.tableCoord[tableId].x, y: this.state.tableCoord[tableId].y }
            this.props.editTable(tableId, { w: newWidth, h: newHeight });
        }

        this.setState((prevstate) => {
            return (
                { tableCoord: { ...prevstate.tableCoord, ...stateForUpdate } }
            )
        })

    }

    handleResizeEnd = () => {
        this.setState({ selectedAction: 'none' });
    };

    handleRemove = (id) => {
        ////console.log('id', id);
        ////console.log('this.state.selectedItem', this.state.selectedItem);
        this.setState({
            stars: this.state.stars.filter((star) => star.id !== this.state.selectedItem),
            selectedAction: 'none',
        });
    };


    setSoundActivation(val) {
        this.props.setSoundActivation(val);

        // Used for raspberry with display - Auth
        if (Utils.getSettingsValue(this.props.settings, 'raspberry_used') == 1 && localStorage.getItem('tabl_raspberry') == 1 && Utils.getSettingsValue(this.props.settings, 'raspberry_display') == '1') {
            this.reqFullScreen();
        }

        if (Auth.getDecodedUser().role != 'SUPER_ADMIN' && Auth.getDecodedUser().role != 'SALES_ADMIN') {
            let body = document.body;
            if (body) {
                body.classList.add('brand-minimized');
                body.classList.add('sidebar-minimized');
            }
        }
    }

    handleCanvasClick = (e) => {
        const { selectedShape, selectedAction, selectedItem, selectedMultipleItems } = this.state;
        const stage = e.target.getStage();
        const position = stage.getPointerPosition();

        if (position) {
            ////console.log('position', position);
            ////console.log('selectedItem', selectedItem);
            ////console.log('selectedAction', selectedAction);
            if (selectedAction === 'resize' && selectedItem) {
                // Resizing mode active, update width and height of the selected shape
                this.setState((prevState) => ({
                    stars: prevState.stars.map((star) => {
                        if (star.id === selectedItem) {
                            return {
                                ...star,
                                width: position.x - star.x,
                                height: position.y - star.y,
                            };
                        }
                        return star;
                    }),
                }));
                this.handleResizeEnd();
            } else {
                if (this.state.drawShape) {
                    // Create a new shape
                    this.setState((prevState) => ({
                        stars: [
                            ...prevState.stars,
                            {
                                id: `${Date.now()}`,
                                x: position.x,
                                y: position.y,
                                shape: selectedShape,
                                isResizing: false,
                                width: 50, // Default width for rectangle
                                height: 50, // Default height for rectangle
                            },
                        ],
                    }));
                }
            }
        }
        this.setState({ drawShape: false })
    };

    filterTablesByRestaurantHallId = (id) => {
        this.setState({ selectedRestaurantHallToShow: id, selectedMultipleItems: [] })
    }

    handleItemSelected = (id) => {
        //console.log('selectedMultipleItems', selectedMultipleItems);
        // 
        let selectedMultipleItems = this.state.selectedMultipleItems;
        //console.log('selectedMultipleItems', selectedMultipleItems);
        if (selectedMultipleItems.includes(id)) {
            // Filtering out the valueToRemove from the array
            selectedMultipleItems = selectedMultipleItems.filter(item => item !== id);
            //console.log('REMOVING:', selectedMultipleItems);
        } else {
            if (selectedMultipleItems.length == 0) {
                selectedMultipleItems.push(id);

                // //console.log('AFTER selectedMultipleItems', selectedMultipleItems);
                // // Mark all -- Todo in the future
                // const resExistingForSelectedOneTable = this.checkIfReservationIsActiveOrUpcoming(id);
                // //console.log('resExistingForSelectedOneTable',resExistingForSelectedOneTable);
                // if (resExistingForSelectedOneTable) {
                //     const currRes = this.state.upcomingReservationsTables.filter(a => a.reservationId == resExistingForSelectedOneTable).map(t => selectedMultipleItems.push(t.id))
                //     // Now when you click again you have to deselect them all
                // }
            } else {
                // Check if the table is not booked

                if (selectedMultipleItems.length == 1 && this.checkIfReservationIsActiveOrUpcoming(selectedMultipleItems[0], 1)) {
                } else {

                    if (!this.checkIfReservationIsActiveOrUpcoming(id, 1)) {
                        selectedMultipleItems.push(id);
                        //console.log('PUSHED', selectedMultipleItems);
                    }
                }
            }
        }
        // 
        this.setState({
            selectedItem: (id === this.state.selectedItem) ? false : id,
            selectedMultipleItems: selectedMultipleItems
        });

        // const resActiveOrUpcomingId = this.checkIfReservationIsActiveOrUpcoming(id);
        // if (resActiveOrUpcomingId) {
        //     this.setState({
        //         createEditReservationButtonText: 'Редактирай резервация',
        //         createEditReservationButtonLink: 'reservations/edit/' + resActiveOrUpcomingId, // THIS IS RESERVATION ID
        //     })
        // } else {
        //     this.setState({
        //         createEditReservationButtonText: 'Създай резервация',
        //         createEditReservationButtonLink: 'reservations/add/' + this.state.selectedMultipleItems, // THIS IS TABLE ID
        //     })
        // }
    };

    checkIfReservationIsActiveOrUpcoming = (tableId, checkIfConfirmed = false) => {

        // Check if tableId exists in upcomingRes
        if (!tableId) {
            return null;
        }
        let isResIdExisting = false;
        let inProgressResExisting = false;
        let resId = null;
        let foundRes = null;
        //TODO this.state.upcomingReservationsTables[tableId]

        // if (!isResIdExisting) {
        this.state.inProgressReservationsTables.forEach(innerArray => {
            //console.log('innerArray', innerArray);
            // innerArray.forEach(obj => {
            //console.log('obj', obj);
            const obj = innerArray;
            if (!resId && obj.reservation_table && obj.reservation_table.tableId == tableId) {
                isResIdExisting = true;
                resId = obj.reservationId;
                foundRes = obj;
                inProgressResExisting = true;
            }
            // });
        });
        // }

        if (!inProgressResExisting) {
            this.state.upcomingReservationsTables.forEach(innerArray => {
                // innerArray.forEach(obj => {
                const obj = innerArray;
                if (!resId && obj.reservation_table && obj.reservation_table.tableId == tableId) {
                    isResIdExisting = true;
                    resId = obj.reservationId;
                    foundRes = obj;
                }
                // });
            });
        }


        if (checkIfConfirmed) {
            if (!foundRes) {
                return false;
            }
            //console.log('foundRes', foundRes);
            // 

            // Get date from reservationsForDate
            const date = new Date(this.state.reservationsForDate).getDate();
            const month = new Date(this.state.reservationsForDate).getMonth();
            const year = new Date(this.state.reservationsForDate).getFullYear();

            // Get time from reservationsForTime
            const hours = new Date(this.state.reservationsForTime).getHours();
            const minutes = new Date(this.state.reservationsForTime).getMinutes();
            const seconds = new Date(this.state.reservationsForTime).getSeconds();

            // Create a new Date object with merged date and time
            const now = new Date(year, month, date, hours, minutes, seconds);
            now.setSeconds(0);
            now.setMilliseconds(0);

            // Get the current date
            const currentDate = now;

            // Calculate the time difference in milliseconds between the current date and the given date
            const timeDifference = currentDate.getTime() - new Date(foundRes.start_time).getTime();
            // Convert milliseconds to minutes
            const timeDifferenceInMinutes = timeDifference / (1000 * 60);
            //console.log('timeDifferenceInMinutes', timeDifferenceInMinutes);
            if (timeDifferenceInMinutes > 30 && foundRes.status == 'unconfirmed') {
                return null
            }
        }


        //console.log('check for tableId', tableId);
        //console.log('this.state.upcomingReservationsTables', this.state.upcomingReservationsTables);
        //console.log('this.state.inProgressReservationsTables', this.state.inProgressReservationsTables);
        //console.log('isResIdExisting', isResIdExisting);
        //console.log('resId', resId);
        return resId;

    }

    prepareReservationData = async (reservationsForDate, reservationsForTime) => {
        // await this.props.listReservations(this.state.search_start_time, this.state.search_end_time, this.state.search_status);

        // let today = new Date().toISOString().split('T')[0];


        let allReservations = Array.isArray(this.props.items) ? this.props.items : [];
        // console.log('IN PREPARE RESERVATIONS', allReservations);
        let reservations = allReservations;
        // console.log('allReservations', allReservations);
        let notCancelledReservationItems = reservations.filter(o => (o.status != "canceled"));
        // let bookedForTodayReservationItems = notCancelledReservationItems.filter(o => (o.status != "canceled"));


        // console.log('bookedForTodayReservationItems', bookedForTodayReservationItems);
        // reservationsForDate
        // reservationsForTime

        // Get date from reservationsForDate
        const date = new Date(reservationsForDate).getDate();
        const month = new Date(reservationsForDate).getMonth();
        const year = new Date(reservationsForDate).getFullYear();

        // Get time from reservationsForTime
        const hours = new Date(reservationsForTime).getHours();
        const minutes = new Date(reservationsForTime).getMinutes();
        const seconds = 0;

        // Create a new Date object with merged date and time
        const mergedDateTime = new Date(year, month, date, hours, minutes, seconds);
        const today = year + '-' + Utils.appendLeadingZeroes(parseInt(month) + 1) + '-' + Utils.appendLeadingZeroes(date);
        // console.log('today', today);
        //console.log('mergedDateTime', mergedDateTime);
        mergedDateTime.setSeconds(0);
        mergedDateTime.setMilliseconds(0);

        const currentTime = new Date(mergedDateTime);
        const oneHourFromNow = new Date(new Date(mergedDateTime).getTime() + 60 * 60 * 2000);
        oneHourFromNow.setSeconds(0);
        oneHourFromNow.setMilliseconds(0);
        ////console.log('notCancelledReservationItems', notCancelledReservationItems);
        // This can be moved up

        let bookedForTodayReservationItems = notCancelledReservationItems.filter(o => {
            const reservationStartTime = new Date(o.start_time);
            reservationStartTime.setSeconds(0);
            reservationStartTime.setMilliseconds(0);


            let reservationDate = new Date(o.start_time).toISOString().split('T')[0];
            return reservationStartTime > currentTime && reservationDate === today;
        });


        const upcomingReservations = notCancelledReservationItems.filter((reservation) => {
            const reservationStartTime = new Date(reservation.start_time);
            reservationStartTime.setSeconds(0);
            reservationStartTime.setMilliseconds(0);
            //console.log('reservation.id', reservation.id);
            //console.log('reservationStartTime', reservationStartTime);
            //console.log('oneHourFromNow', oneHourFromNow);
            return reservationStartTime > currentTime && reservationStartTime <= oneHourFromNow && reservation.status != 'confirmed'
        });
        //console.log('upcomingReservations', upcomingReservations);

        // let prevInProgressTablesWithColors = this.state.tablesWithColors['inprogress'];
        let newExpectingTablesWithColors = {},
            newNotArrivedTablesWithColors = {},
            newConfirmedTablesWithColor = {},
            newBookedForTodayTablesWithColor = {};


        const inProgressReservations = notCancelledReservationItems.filter((reservation) => {
            const reservationStartTime = new Date(reservation.start_time);
            reservationStartTime.setSeconds(0);
            reservationStartTime.setMilliseconds(0);

            const reservationEndTime = new Date(reservation.end_time);
            reservationEndTime.setSeconds(0);
            reservationEndTime.setMilliseconds(0);
            return (reservationStartTime <= currentTime && reservationEndTime > currentTime) ||
                (reservationStartTime > currentTime && reservationStartTime <= oneHourFromNow && reservation.status == 'confirmed')
        });

        // console.log('inProgressReservations', inProgressReservations);

        // const upcomingReservationsTables = upcomingReservations.map(r => r.tables);
        // const inProgressReservationsTables = inProgressReservations.map(r => r.tables);

        const bookedForTodayReservationsTables = bookedForTodayReservationItems.flatMap(r =>
            r.tables.map(table => {

                newBookedForTodayTablesWithColor[table.id] = true

                return ({
                    reservationId: r.id,
                    status: r.status,
                    start_time: r.start_time,
                    end_time: r.end_time,
                    client_name: r.client_name,
                    people_count: r.people_count,
                    description: r.description,
                    menu_included: r.menu_included,
                    table_choosed: r.table_choosed,
                    paid_ammount: r.paid_ammount,
                    allTables: r.tables,
                    ...table
                })
            })
        );


        const upcomingReservationsTables = upcomingReservations.flatMap(r =>
            r.tables.map(table => {

                newExpectingTablesWithColors[table.id] = true

                return ({
                    reservationId: r.id,
                    status: r.status,
                    start_time: r.start_time,
                    end_time: r.end_time,
                    client_name: r.client_name,
                    people_count: r.people_count,
                    description: r.description,
                    menu_included: r.menu_included,
                    table_choosed: r.table_choosed,
                    paid_ammount: r.paid_ammount,
                    allTables: r.tables,
                    ...table
                })
            })
        );

        // const currentTime = new Date(mergedDateTime);

        const currentDate = currentTime;

        const inProgressReservationsTables = inProgressReservations.flatMap(r =>
            r.tables.map(table => {

                const timeDifference = currentDate.getTime() - new Date(r.start_time).getTime();
                // Convert milliseconds to minutes
                const timeDifferenceInMinutes = timeDifference / (1000 * 60);

                if (r.status == 'unconfirmed' && timeDifferenceInMinutes <= 30) {
                    newNotArrivedTablesWithColors[table.id] = true // blue
                } else {
                    if (r.status == 'confirmed') {
                        newConfirmedTablesWithColor[table.id] = true // red
                        // } else {
                        //     return '#00e100'; //green
                    }
                }

                return ({
                    reservationId: r.id,
                    status: r.status,
                    start_time: r.start_time,
                    end_time: r.end_time,
                    client_name: r.client_name,
                    people_count: r.people_count,
                    description: r.description,
                    menu_included: r.menu_included,
                    table_choosed: r.table_choosed,
                    paid_ammount: r.paid_ammount,
                    allTables: r.tables,
                    ...table
                })
            })
        );

        //console.log('SAVING: upcomingReservationsTables', upcomingReservationsTables)
        //console.log('SAVING: inProgressReservationsTables', inProgressReservationsTables)

        // if (!this.state.reservationsPrepared) {
        //     this.setState({ upcomingReservationsTables: upcomingReservationsTables, inProgressReservationsTables: inProgressReservationsTables, reservationsPrepared: true });
        // } else {

        const newTablesWithColors = {
            ['expecting']: newExpectingTablesWithColors,
            ['notarrived']: newNotArrivedTablesWithColors,
            ['confirmed']: newConfirmedTablesWithColor,
            ['bookedForToday']: newBookedForTodayTablesWithColor,

        }

        let tablesWithNumbers = [];
        if (this.state.tablesWithNumbers && Object.values(this.state.tablesWithNumbers).length > 0) {
            tablesWithNumbers = this.state.tablesWithNumbers
        } else {
            // console.log('this.props.tables', this.props.tables);
            Object.values(this.props.tables).filter(t => t.is_for_reservation == 1).map((table) => {
                // console.log('setting table', table);
                tablesWithNumbers.push({ tableId: table.id, tableNum: table.table_num })
            })

        }
        // console.log('tablesWithNumbers', tablesWithNumbers);

        this.setState({
            bookedForTodayReservationsTables: bookedForTodayReservationsTables,
            upcomingReservationsTables: upcomingReservationsTables,
            inProgressReservationsTables: inProgressReservationsTables,
            tablesWithColors: newTablesWithColors,
            tablesWithNumbers: tablesWithNumbers

        });
        // }

        // 

    }


    handleDateChange = (calendarDate) => {
        this.setState({
            reservationsForDate: calendarDate,
            isSelectedNow: false,
            selectedItem: false,
            selectedMultipleItems: [],
        });
        // console.log('CALLED PREPARE RESERVATIONS',);
        this.prepareReservationData(calendarDate, this.state.reservationsForTime);



        // this.prepareReservationData();
        // //console.log('calendarDate', calendarDate)
        // //console.log('calendarDate.getMinutes()', calendarDate.getMinutes())
        // let data = [];
        // data.target = [];
        // data.target.name = 'date_time_from';
        // if (calendarDate.getMinutes() != 0
        //     && calendarDate.getMinutes() != 15
        //     && calendarDate.getMinutes() != 30
        //     && calendarDate.getMinutes() != 45
        // ) {
        //     calendarDate.setMinutes(0);
        // }
        // data.target.value = calendarDate;

        // props.onChange(data);
    }
    handleTimeChange = (calendarTime) => {
        this.setState({
            reservationsForTime: calendarTime,
            isSelectedNow: false,
            selectedItem: false,
            selectedMultipleItems: [],
        });
        // this.prepareReservationData();
        // console.log('CALLED PREPARE RESERVATIONS',);
        this.prepareReservationData(this.state.reservationsForDate, calendarTime);

    }

    setReservationTimeNow = () => {
        this.setState({
            isSelectedNow: true,
            selectedItem: false,
            selectedMultipleItems: [],
        })
        // console.log('CALLED PREPARE RESERVATIONS',);
        this.prepareReservationData(new Date(), new Date());

    }

    //     onChange={this.handleDateChange}

    //     onChange={this.handleTimeChange}

    // <button className="btn btn-default btn-ladda ladda-button" disabled={this.state.isSelectedNow} onClick={()=>setReservationTimeNow()}>


    // reservationsForDate
    // handleDateChange
    // reservationsForTime
    // handleTimeChange


    getReservationDataByTable = (tableId) => {
        const self = this;
        ////console.log('in getReservationDataByTable', tableId);
        // let allReservations = this.props.items;
        let allReservations = Array.isArray(this.props.items) ? this.props.items : [];

        ////console.log('allReservations', allReservations);
        let reservations = allReservations;


        // let notCancelledReservationItems = reservations.filter(o => o.status !== "canceled");


        // Get date from reservationsForDate
        const date = new Date(this.state.reservationsForDate).getDate();
        const month = new Date(this.state.reservationsForDate).getMonth();
        const year = new Date(this.state.reservationsForDate).getFullYear();

        // Get time from reservationsForTime
        const hours = new Date(this.state.reservationsForTime).getHours();
        const minutes = new Date(this.state.reservationsForTime).getMinutes();
        const seconds = new Date(this.state.reservationsForTime).getSeconds();

        // Create a new Date object with merged date and time
        const now = new Date(year, month, date, hours, minutes, seconds);
        now.setSeconds(0);
        now.setMilliseconds(0);

        // const now = new Date(this.state.reservationsForTime);

        const RESERVATION_TIMEHourAgo = new Date(now.getTime() - 3 * 60 * 60 * 1000); // Subtract 1 hour in milliseconds
        let notCancelledReservationItems;
        if (Array.isArray(reservations)) {
            notCancelledReservationItems = reservations.filter(reservation => {
                const startTime = new Date(reservation.start_time);
                startTime.setSeconds(0);
                startTime.setMilliseconds(0);
                const endTime = new Date(reservation.end_time);
                endTime.setSeconds(0);
                endTime.setMilliseconds(0);
                return (
                    reservation.status !== "canceled" &&
                    startTime >= RESERVATION_TIMEHourAgo &&
                    endTime > now
                );
            });
        }

        function isReservationActive(reservation) {
            //console.log('reservation.id', reservation.id);
            //console.log('reservation.start_time', reservation.start_time);
            //console.log('reservation.end_time', reservation.end_time);
            let startTimeDate = new Date(reservation.start_time)
            let endTimeDate = new Date(reservation.end_time)
            let currentTimeDate = new Date(now)
            startTimeDate.setSeconds(0);
            startTimeDate.setMilliseconds(0);

            endTimeDate.setSeconds(0);
            endTimeDate.setMilliseconds(0);

            currentTimeDate.setSeconds(0);
            currentTimeDate.setMilliseconds(0);

            const startTime = startTimeDate.getTime();
            const endTime = endTimeDate.getTime();
            const currentTime = currentTimeDate.getTime();


            /// Calculation for not confirmed reservation
            const currentDate = now;

            // Calculate the time difference in milliseconds between the current date and the given date
            const timeDifference = currentDate.getTime() - new Date(reservation.start_time).getTime();
            // Convert milliseconds to minutes
            const timeDifferenceInMinutes = timeDifference / (1000 * 60);
            //console.log('timeDifferenceInMinutes', timeDifferenceInMinutes);
            //console.log('reservation.id', reservation.id);
            //console.log('reservation.status', reservation.status);
            if (timeDifferenceInMinutes > 30 && reservation.status == 'unconfirmed') {
                return false;
            }

            ///
            //console.log('currentTime', currentTime);
            const oneHourInMillis = 60 * 60 * 2000; // One hour in milliseconds
            //console.log('startTime', startTime);
            //console.log('oneHourInMillis', oneHourInMillis);
            //console.log('currentTime', currentTime);
            //console.log('endTime', endTime);
            //console.log('startTime - oneHourInMillis <= currentTime && currentTime < endTime', startTime - oneHourInMillis <= currentTime && currentTime < endTime);
            return startTime - oneHourInMillis <= currentTime && currentTime < endTime;
        }



        //console.log('notCancelledReservationItems', notCancelledReservationItems);
        // Loop through the objects in the data array
        for (const reservation of notCancelledReservationItems) {
            // Check if the reservation has a "tables" array
            if (reservation.tables && Array.isArray(reservation.tables)) {
                // Find the table with the matching tableId
                const table = reservation.tables.find(table => table.id === tableId);
                if (table) {
                    if (isReservationActive(reservation)) {

                        let startTime = new Date(reservation.start_time);
                        let endTime = new Date(reservation.end_time);

                        startTime.setSeconds(0);
                        startTime.setMilliseconds(0);

                        endTime.setSeconds(0);
                        endTime.setMilliseconds(0);

                        // Format start_time and end_time to "HH:mm" format in 24-hour format
                        const startTimeFormatted = startTime.toLocaleTimeString('en-US', { hour: '2-digit', minute: '2-digit', hour12: false });
                        const endTimeFormatted = endTime.toLocaleTimeString('en-US', { hour: '2-digit', minute: '2-digit', hour12: false });

                        return reservation.client_name.substring(0, 10) + '...'
                        return `${startTimeFormatted}-${endTimeFormatted}`;

                    }
                }
            }
        }

        // If no matching tableId is found, return null or an appropriate default value
        return null;

    }


    setActiveColorForTable = (tableId) => {
        let newExpectingTablesWithColors = this.state.tablesWithColors['expecting'];
        let newNotArrivedTablesWithColors = this.state.tablesWithColors['notarrived'];
        let newConfirmedTablesWithColor = this.state.tablesWithColors['confirmed'];
        let newBookedForTodayTablesWithColor = this.state.tablesWithColors['bookedForToday'];

        if (newConfirmedTablesWithColor[tableId]) {
            return '#ff2626'; // red
        } else if (newExpectingTablesWithColors[tableId]) {
            return '#ffff00'; // yellow
        } else if (newNotArrivedTablesWithColors[tableId]) {
            return '#b1b1ff'; // blue
        } else if (newBookedForTodayTablesWithColor[tableId]) {
            return '#ccc'; // gray
        } else {
            return '#00e100' // green
        }


        return '#ccc';

        const upcomingReservationsTables = this.state.upcomingReservationsTables || [];
        const inProgressReservationsTables = this.state.inProgressReservationsTables || [];

        //console.log('inProgressReservationsTables', inProgressReservationsTables);

        let isUpcomingResFound = false;
        if (
            (upcomingReservationsTables && Object.values(upcomingReservationsTables) && Object.values(upcomingReservationsTables).length > 0)
            ||
            (
                Array.isArray(upcomingReservationsTables) && upcomingReservationsTables.length > 0
            )
        ) {
            isUpcomingResFound = upcomingReservationsTables.find(item => item.id === tableId);
        }

        // isUpcomingResFound = upcomingReservationsTables.some((nestedArray) =>
        //     nestedArray.some((item) => item.id === tableId)
        // );
        ////console.log('isUpcomingResFound', isUpcomingResFound);

        if (isUpcomingResFound) {
            return '#ffff00'; // yellow
        }




        let isInProgressResFound = false;
        // if (
        //     (inProgressReservationsTables && Object.values(inProgressReservationsTables) && Object.values(inProgressReservationsTables).length > 0)
        //     ||
        //     (
        //         Array.isArray(inProgressReservationsTables) && inProgressReservationsTables.length > 0
        //     )
        // )
        //     //console.log('INSIDE inProgressReservationsTables: ', inProgressReservationsTables);
        // isInProgressResFound = inProgressReservationsTables.some((nestedArray) =>
        //     nestedArray.some((item) => item.id === tableId)
        // );



        isInProgressResFound = inProgressReservationsTables.find(item => item.id === tableId);



        ////console.log('isInProgressResFound', isInProgressResFound);

        if (isInProgressResFound) {
            //console.log('inProgressReservationsTables', inProgressReservationsTables);
            //console.log('isInProgressResFound', isInProgressResFound);


            // Get date from reservationsForDate
            const date = new Date(this.state.reservationsForDate).getDate();
            const month = new Date(this.state.reservationsForDate).getMonth();
            const year = new Date(this.state.reservationsForDate).getFullYear();

            // Get time from reservationsForTime
            const hours = new Date(this.state.reservationsForTime).getHours();
            const minutes = new Date(this.state.reservationsForTime).getMinutes();
            const seconds = new Date(this.state.reservationsForTime).getSeconds();

            // Create a new Date object with merged date and time
            const now = new Date(year, month, date, hours, minutes, seconds);
            now.setSeconds(0);
            now.setMilliseconds(0);

            // Get the current date
            const currentDate = now;

            // Calculate the time difference in milliseconds between the current date and the given date
            const timeDifference = currentDate.getTime() - new Date(isInProgressResFound.start_time).getTime();
            // Convert milliseconds to minutes
            const timeDifferenceInMinutes = timeDifference / (1000 * 60);
            //console.log('timeDifferenceInMinutes', timeDifferenceInMinutes);
            if (timeDifferenceInMinutes <= 30 && isInProgressResFound.status == 'unconfirmed') {
                return '#b1b1ff'; // blue

            } else {
                if (isInProgressResFound.status == 'confirmed') {
                    return '#ff2626'; // red
                } else {
                    return '#00e100'; //green
                }
            }

        }



        return '#00e100' // green
    }

    compareStartTimes = (reservationA, reservationB) => {
        const timeA = new Date(reservationA.start_time).getTime();
        const timeB = new Date(reservationB.start_time).getTime();
        return timeA - timeB;
    }

    onCloseNewReservationDialog = (e, maxId) => {
        console.log('maxId', maxId);
        this.setState({ reservationModalClosed: true });
        this.props.markReservationsAsNotified(maxId, Auth.getUserPlace());

    }

    render() {
        // console.log('this.state.showAllResDescForToday',this.state.showAllResDescForToday);

        const namesStatuses = {
            'unconfirmed': 'НЕПОТВЪРДЕНА',
            'canceled': 'ОТКАЗАНА',
            'confirmed': 'ПОТВЪРДЕНА',
        }
        // console.log('tablesWithNumbers', this.state.tablesWithNumbers);
        // console.log('this.state.realLoaderLoading', this.state.realLoaderLoading);
        const { stars, selectedShape, selectedAction, selectedItem, selectedMultipleItems, filterListingRes } = this.state;
        // let allReservations = this.props.items
        let allReservations = Array.isArray(this.props.items) ? this.props.items : [];


        let listedReservations = Array.isArray(allReservations) ? allReservations : Object.values(allReservations) || [];
        // console.log('listedReservations', listedReservations);
        if (listedReservations && listedReservations.length > 0) {
            listedReservations.sort(this.compareStartTimes);
        }
        console.log('listedReservations', listedReservations);

        let showNotificationsReservations = []
        if (localStorage.getItem('tabl_reservations_main_tablet') == 1) {
            showNotificationsReservations = listedReservations.filter(r => r.notification_showed != 1)
        }
        console.log('showNotificationsReservations', showNotificationsReservations);

        // Get date from reservationsForDate
        const date = new Date(this.state.reservationsForDate).getDate();
        const month = new Date(this.state.reservationsForDate).getMonth();
        const year = new Date(this.state.reservationsForDate).getFullYear();

        // Get time from reservationsForTime
        const hours = new Date(this.state.reservationsForTime).getHours();
        const minutes = new Date(this.state.reservationsForTime).getMinutes();
        const seconds = 0;

        // Create a new Date object with merged date and time
        const mergedDateTime = new Date(year, month, date, hours, minutes, seconds);
        //console.log('mergedDateTime', mergedDateTime);
        mergedDateTime.setSeconds(0);
        mergedDateTime.setMilliseconds(0);
        const currentDate = mergedDateTime;
        // console.log('filterListingRes', filterListingRes);

        if (filterListingRes == 1) {
            listedReservations = listedReservations.filter(reservation => {
                const startTime = new Date(reservation.start_time);
                startTime.setSeconds(0);
                startTime.setMilliseconds(0);

                let timeThreshold = new Date((startTime).getTime() + 30 * 60000);


                return timeThreshold >= mergedDateTime;
            });
        } else if (filterListingRes == 2) {
        } else if (filterListingRes == 3) {
            listedReservations = listedReservations.filter(reservation => {
                const startTime = new Date(reservation.start_time);
                startTime.setSeconds(0);
                startTime.setMilliseconds(0);

                const differenceInMs = startTime - currentDate;

                // Convert milliseconds to minutes
                const minutesDifference = Math.floor(differenceInMs / (1000 * 60));

                return minutesDifference > 0 && minutesDifference <= 60;
            });
        } else if (filterListingRes == 4) {

            listedReservations = listedReservations.filter(reservation => {
                const startTime = new Date(reservation.start_time);
                startTime.setSeconds(0);
                startTime.setMilliseconds(0);

                const differenceInMs = startTime - currentDate;

                // Convert milliseconds to minutes
                const minutesDifference = Math.floor(differenceInMs / (1000 * 60));

                return minutesDifference >= -30 && minutesDifference <= 0 && reservation.status == 'unconfirmed'
            });

        } else if (filterListingRes == 5) {

            listedReservations = listedReservations.filter(reservation => {
                const startTime = new Date(reservation.start_time);
                startTime.setSeconds(0);
                startTime.setMilliseconds(0);

                const endDate = new Date(reservation.end_time);
                endDate.setSeconds(0);
                endDate.setMilliseconds(0);

                return currentDate >= startTime && currentDate <= endDate && reservation.status == 'confirmed'

            });

        }





        // console.log('allReservations', allReservations);
        // let unseenReservationItems = 0;
        // unseenReservationItems = listedReservations.filter(o => o.seen == 0).length;
        // let notContirmedReservationItems = listedReservations.filter(o => (o.status == "unconfirmed"));
        // let confirmedReservationItems = listedReservations.filter(o => (o.status == "confirmed"));
        // let canceledReservationItems = listedReservations.filter(o => (o.status == "canceled"));
        // let withoutTableReservationItems = listedReservations.filter(o => (o.tables.length == 0));

        // if (this.state.activeTab == 2) {
        //     listedReservations = notContirmedReservationItems;
        // } else if (this.state.activeTab == 3) {
        //     listedReservations = confirmedReservationItems;
        // } else if (this.state.activeTab == 4) {
        //     listedReservations = canceledReservationItems;
        // } else if (this.state.activeTab == 5) {
        //     listedReservations = withoutTableReservationItems;
        // }


        // listedReservations = listedReservations.sort((a, b) => new Date(b.start_time) - new Date(a.start_time))

        if (this.state.makeFiltering) {
            if (this.state.search_client_name) {
                listedReservations = listedReservations.filter(a => a.client_name.toLocaleLowerCase().includes(this.state.search_client_name.toLocaleLowerCase()));
            }
            if (this.state.search_client_phone) {
                listedReservations = listedReservations.filter(a => a.client_phone.toLocaleLowerCase().includes(this.state.search_client_phone.toLocaleLowerCase()));

            }
            // listedReservations = listedReservations.sort((a, b) => new Date(b.start_time) - new Date(a.start_time))

        }



        ////console.log('reservations', reservations);
        let autocompleteStatuses = []
        this.statuses.map(status => {
            autocompleteStatuses.push({ 'value': status, 'label': this.props.translations.reservations.statuses[status] });
        });
        // if (this.state.search_name.length > 0
        //     || this.state.search_active != 0
        // ) {
        //     catItems = Object.values(catItems).filter(i => {
        //         return (
        //             ((i.name).toLocaleLowerCase().includes((this.state.search_name).toLocaleLowerCase()))
        //             && (this.state.search_active != 0 ? i.active == Boolean(parseInt(this.state.search_active) - 1) : true)
        //         )
        //     })
        // }

        const items_count = (listedReservations).length
        const current_page = this.props.page;
        const start_offset = (current_page - 1) * ITEMS_PER_PAGE;
        let start_count = 0;

        const actionText = this.props.isAdding ? 'Добави' : 'Запази';
        const uniqueReservations = [];

        ////console.log('this.props.tableRegions', this.props.tableRegions);

        const currentTableHaveActiveRes = this.checkIfReservationIsActiveOrUpcoming(this.state.selectedMultipleItems[0], 1)

        // console.log('bookedForTodayReservationsTables', this.state.bookedForTodayReservationsTables);

        console.log('this.state.reservationModalClosed', this.state.reservationModalClosed);
        console.log('showNotificationsReservations.length', showNotificationsReservations.length);


        return (
            <>
                {this.state.savingLoaderLoading ?
                    <CircularProgress style={{
                        position: ' fixed',
                        width: '100px',
                        height: '100px',
                        left: 'calc(50% - 50px)',
                        top: 'calc(50% - 50px)',
                        zIndex: ' 999',
                    }} />
                    :
                    null
                }


                <SoundActivatedDialog
                    soundActivated={this.props.soundActivated}
                    activateSound={() => this.setSoundActivation(true)}
                    translations={this.props.translations}
                />

                {this.props.soundActivated ?
                    <>
                        <NewReservationDialog
                            // soundActivated={false}
                            showNotificationsReservations={showNotificationsReservations}
                            open={this.state.reservationModalClosed == false && showNotificationsReservations.length > 0}
                            onClose={this.onCloseNewReservationDialog}
                            translations={this.props.translations}
                        />

                        {this.state.reservationModalClosed == false && showNotificationsReservations.length > 0 ?
                            <Sound
                                url={SoundReservation}
                                playStatus={Sound.status.PLAYING}
                            />
                            :
                            null
                        }

                    </>
                    :
                    null
                }



                <main className="main" style={{ opacity: !this.state.savingLoaderLoading ? 1 : 0.6 }}>
                    {/* <!-- Breadcrumb--> */}
                    <ol className="breadcrumb">
                        <li className="breadcrumb-item"><NavLink to={ADMIN_URL}>{this.props.translations.common.dashboard}</NavLink></li>
                        <li className="breadcrumb-item active">{this.props.translations.reservations.title}</li>
                        <li className="breadcrumb-menu d-md-down-none">
                            <div className="btn-group" role="group" aria-label="Button group">
                                <Button className="btn"
                                    title={localStorage.getItem('tabl_reservations_main_tablet') == 1 ?
                                        "Таблета е зададен като основен. Ако премахнете, така няма да получавате нотификация, когато някой друг ваш колега (или клиент) направи резервация. Желаете ли да го направите ?"
                                        : "Задайте таблета като основен. Така ще получавате нотификация, когато някой друг ваш колега (или клиент) направи резервация ?"
                                    }
                                    onClick={() => {
                                        window.confirm(
                                            localStorage.getItem('tabl_reservations_main_tablet') == 1 ?
                                                "Таблета е зададен като основен. Ако премахнете, така няма да получавате нотификация, когато някой друг ваш колега (или клиент) направи резервация. Желаете ли да го направите ?"
                                                : "Задайте таблета като основен. Така ще получавате нотификация, когато някой друг ваш колега (или клиент) направи резервация ?"
                                        )
                                            ? (localStorage.getItem('tabl_reservations_main_tablet') == 1 ? this.addTabletAsMainForReservation('tabl_reservations_main_tablet', 0) : this.addTabletAsMainForReservation('tabl_reservations_main_tablet', 1))
                                            : void (0)
                                    }}
                                >
                                    <i className="nav-icon icon-tablet"></i> &nbsp; Основен таблет - {localStorage.getItem('tabl_reservations_main_tablet') == 1 ? 'ДА' : 'НЕ'}
                                </Button>
                            </div>
                            &nbsp;
                            <div className="btn-group" role="group" aria-label="Button group">
                                <NavLink to={ADMIN_URL + `tableGroups`} className="btn" >
                                    <i className="nav-icon icon-settings"></i> &nbsp; {this.props.translations.table_groups.title}
                                </NavLink>
                            </div>
                            &nbsp;
                            {/* <div className="btn-group" role="group" aria-label="Button group">
                                <button className="btn btn-warning btn-ladda ladda-button" style={{ padding: 5, color: 'white' }}
                                    onClick={() => this.setState({ visibleFilter: !this.state.visibleFilter })}
                                >
                                    {this.props.translations.common.filter}
                                </button>
                            </div> */}

                            &nbsp;
                            <i className={"nav-icon hoverable " + (Utils.getOrdersStyleType() == 'row' ? 'icon-grid' : 'icon-list')} onClick={this.props.changeOrdersStyleType} style={{
                                fontSize: 20,
                                background: 'white',
                                'borderRadius': '50%',
                                'verticalAlign': 'middle',
                                border: '1px solid #ccc',
                                padding: 9
                            }}></i>
                        </li>

                    </ol>
                    {/* 
                    <SoundActivatedDialog
                        soundActivated={this.props.soundActivated}
                        activateSound={() => this.props.setSoundActivation(true)}
                        translations={this.props.translations}
                    /> */}

                    {/* {
                        this.props.soundActivated ?
                            <React.Fragment>
                                {unseenReservationItems > 0 ?
                                    <Sound
                                        url={soundfileReservation}
                                        playStatus={Sound.status.PLAYING}
                                    />
                                    :
                                    null
                                }
                            </React.Fragment>
                            :
                            null
                    } */}

                    <div className="container-fluid">
                        <div className="animated fadeIn">
                            <div className="row">

                                {/* <!-- /.col--> */}
                                <div className="col-lg-12 p-0">
                                    {/* {this.state.visibleFilter ?
                                        <Filter
                                            search_client_name={this.state.search_client_name}
                                            search_client_phone={this.state.search_client_phone}
                                            search_start_time={this.state.search_start_time}
                                            search_end_time={this.state.search_end_time}
                                            search_status={this.state.search_status}
                                            statuses={this.statuses}
                                            translations={this.props.translations}
                                            onDateChange={this.onDateChange}
                                            onChange={this.onChange}
                                            onChangeSelectAutocomplete={this.onChangeSelectAutocomplete}
                                            callFilter={this.callFilter}
                                        />
                                        :
                                        null
                                    }
                                    <MessagesContainer /> */}

                                    <div className="main-reseravation-pickers-container" >
                                        <MuiPickersUtilsProvider utils={DateFnsUtils} className="w-100" locale={this.localeMap[this.state.locale]}>
                                            <DatePicker
                                                // disablePast
                                                autoOk
                                                ampm={false}
                                                value={this.state.reservationsForDate}
                                                onChange={this.handleDateChange}
                                                // className="w-100"
                                                // shouldDisableDate={disableForbiddenDates}
                                                format="dd/MM/yyyy"
                                                minutesStep="15"
                                                variant="outlined"

                                            />
                                            &nbsp;
                                            &nbsp;
                                            <TimePicker
                                                // disablePast
                                                autoOk
                                                ampm={false}
                                                value={this.state.reservationsForTime}
                                                onChange={this.handleTimeChange}
                                                // className="w-100"
                                                // shouldDisableDate={disableForbiddenDates}
                                                format="HH:mm"
                                                minutesStep="15"
                                                variant="outlined"

                                            />
                                        </MuiPickersUtilsProvider>

                                        <button className="btn btn-default btn-ladda ladda-button" disabled={this.state.isSelectedNow} onClick={() => this.setReservationTimeNow()}>
                                            СЕГА
                                        </button>

                                    </div>


                                    {Utils.getOrdersStyleType() == 'row' ?

                                        <div className="card">

                                            <span className="legend-status-info-container" style={{ float: 'right' }}>
                                                <span className="legend-status-info-each" style={{ background: '#00e100' }}>Свободна</span>
                                                <span className="legend-status-info-each" style={{ background: '#ccc' }}>С поне 1 рез. за деня</span>
                                                <span className="legend-status-info-each" style={{ background: 'yellow' }}>Очаква до 2 часа</span>
                                                <span className="legend-status-info-each" style={{ background: '#b1b1ff' }}>Закъснява до 30 мин</span>
                                                <span className="legend-status-info-each" style={{ background: '#ff3636' }}>Заета</span>
                                            </span>
                                            <div className="card-body">


                                                <div>
                                                    <div>
                                                        {/* <button onClick={() => this.handleShapeChange('star')}>Draw Star</button>
                                                    <button onClick={() => this.handleShapeChange('circle')}>Draw Circle</button>
                                                    <button onClick={() => this.handleShapeChange('square')}>Draw Square</button> */}

                                                        {/* <button className="btn btn-warning btn-ladda ladda-button" style={{ padding: 5, color: 'white' }} */}





                                                        {/* {this.state.selectedItem ?
                                                        <NavLink to={ADMIN_URL + this.state.createEditReservationButtonLink} className="nav-link" style={{ padding: 0, color: 'initial', display: 'inline' }}>
                                                            <button className="btn btn-default btn-ladda ladda-button" disabled={!this.state.selectedItem}>
                                                                {this.state.createEditReservationButtonText}
                                                            </button>
                                                        </NavLink>
                                                        :
                                                        <button className="btn btn-default btn-ladda ladda-button" disabled={!this.state.selectedItem}>
                                                            {this.state.createEditReservationButtonText}
                                                        </button>
                                                    }
                                                    &nbsp; */}


                                                        {/* <button className="btn btn-default btn-ladda ladda-button"
                                                        disabled={!(this.state.selectedItem && this.checkIfReservationIsActiveOrUpcoming(this.state.selectedItem))}
                                                        onClick={() => this.onChangeSelectAutocompleteStatus(this.checkIfReservationIsActiveOrUpcoming(this.state.selectedItem), 'confirmed')}>
                                                        Създай резервация
                                                    </button> */}
                                                        {/* {selectedMultipleItems.length} */}

                                                        {Auth.hasAccess('PLACE_ADMIN') ?
                                                            <>
                                                                {((this.state.selectedMultipleItems.length == 1 && !currentTableHaveActiveRes)
                                                                    || (this.state.selectedMultipleItems.length > 1)
                                                                ) ?
                                                                    <NavLink to={ADMIN_URL + 'reservations/add/' + this.state.selectedMultipleItems + '/' + this.state.selectedRestaurantHallToShow + '/' + Date.parse(mergedDateTime)} className="nav-link" style={{ padding: 0, color: 'initial', display: 'inline' }}>
                                                                        <button className="btn btn-default btn-ladda ladda-button"
                                                                        >
                                                                            Създай
                                                                        </button>
                                                                    </NavLink>
                                                                    :
                                                                    <button className="btn btn-default btn-ladda ladda-button"
                                                                        disabled={true}>
                                                                        Създай
                                                                    </button>
                                                                }
                                                            </>
                                                            :
                                                            null
                                                        }

                                                        {Auth.hasAccess('PLACE_ADMIN') ?
                                                            <>

                                                                &nbsp;
                                                                {this.state.selectedMultipleItems.length == 1 && currentTableHaveActiveRes ?
                                                                    <NavLink to={ADMIN_URL + 'reservations/edit/' + currentTableHaveActiveRes} className="nav-link" style={{ padding: 0, color: 'initial', display: 'inline' }}>
                                                                        <button className="btn btn-default btn-ladda ladda-button"
                                                                        >
                                                                            Редактирай
                                                                        </button>
                                                                    </NavLink>
                                                                    :
                                                                    <button className="btn btn-default btn-ladda ladda-button"
                                                                        disabled={true}>
                                                                        Редактирай
                                                                    </button>
                                                                }
                                                            </>
                                                            :
                                                            null
                                                        }

                                                        &nbsp;
                                                        {(this.state.selectedMultipleItems.length == 1 && currentTableHaveActiveRes) || this.state.movingReservationId ?
                                                            // <NavLink to={ADMIN_URL + 'reservations/edit/' + currentTableHaveActiveRes} className="nav-link" style={{ padding: 0, color: 'initial', display: 'inline' }}>
                                                            <button className="btn btn-default btn-ladda ladda-button"
                                                                onClick={() => this.state.movingReservationId ? this.stopMovingReservation() : this.startMovingReservation(currentTableHaveActiveRes)}
                                                            >
                                                                Премести
                                                            </button>
                                                            // </NavLink>
                                                            :
                                                            <button className="btn btn-default btn-ladda ladda-button"
                                                                disabled={true}>
                                                                Премести
                                                            </button>
                                                        }
                                                        &nbsp;
                                                        {this.state.selectedMultipleItems.length == 1 && currentTableHaveActiveRes ?
                                                            <button className="btn btn-default btn-ladda ladda-button"
                                                                onClick={() => this.onChangeSelectAutocompleteStatusParam(currentTableHaveActiveRes, 'confirmed')}>
                                                                Потвърди
                                                            </button>
                                                            :
                                                            <button className="btn btn-default btn-ladda ladda-button"
                                                                disabled={true}>
                                                                Потвърди
                                                            </button>
                                                        }
                                                        {this.state.selectedMultipleItems.length >= 1 && !currentTableHaveActiveRes ?
                                                            <button className="btn btn-default btn-ladda ladda-button"
                                                                onClick={() => this.createReservationFromAdmin(mergedDateTime)}>
                                                                Резервирай за {Utils.appendLeadingZeroes(mergedDateTime.getDate())}.{Utils.appendLeadingZeroes(mergedDateTime.getMonth() + 1)} / {Utils.appendLeadingZeroes(mergedDateTime.getHours())}:{Utils.appendLeadingZeroes(mergedDateTime.getMinutes())}
                                                            </button>
                                                            :
                                                            <button className="btn btn-default btn-ladda ladda-button"
                                                                disabled={true}>
                                                                Резервирай за {Utils.appendLeadingZeroes(mergedDateTime.getDate())}.{Utils.appendLeadingZeroes(mergedDateTime.getMonth() + 1)} / {Utils.appendLeadingZeroes(mergedDateTime.getHours())}:{Utils.appendLeadingZeroes(mergedDateTime.getMinutes())}
                                                            </button>
                                                        }
                                                        {this.state.selectedMultipleItems.length >= 1 && currentTableHaveActiveRes ?
                                                            <button className="btn btn-default btn-ladda ladda-button"
                                                                onClick={() => this.onChangeSelectAutocompleteStatusParam(currentTableHaveActiveRes, 'canceled')}>
                                                                Откажи
                                                            </button>
                                                            :
                                                            <button className="btn btn-default btn-ladda ladda-button"
                                                                disabled={true}>
                                                                Откажи
                                                            </button>
                                                        }



                                                        {/* <button className="btn btn-default btn-ladda ladda-button"
                                                        disabled={!(this.state.selectedItem && this.checkIfReservationIsActiveOrUpcoming(this.state.selectedItem))}
                                                        onClick={() => this.onChangeSelectAutocompleteStatus(this.checkIfReservationIsActiveOrUpcoming(this.state.selectedItem), 'confirmed')}>
                                                        Потвърди резервацията
                                                    </button> */}

                                                        <button className="btn btn-default btn-ladda ladda-button" onClick={() => this.setState({ visibleMoreSettings: !this.state.visibleMoreSettings })}>Oще</button>
                                                        {this.state.visibleMoreSettings ?
                                                            <>
                                                                &nbsp;
                                                                <button className="btn btn-default btn-ladda ladda-button" disabled={this.state.enableDragging} onClick={() => this.setState({ enableDragging: true })}>Позволи разместване</button>
                                                                &nbsp;
                                                                <button className="btn btn-default btn-ladda ladda-button" disabled={!this.state.enableDragging} onClick={() => this.setState({ enableDragging: false })}>Спри разместване</button>
                                                                &nbsp;
                                                                <button className="btn btn-info btn-ladda ladda-button" disabled={this.state.selectedMultipleItems.length == 0} onClick={() => this.handleResize(true)}>Уголеми маса +</button>
                                                                &nbsp;
                                                                <button className="btn btn-info btn-ladda ladda-button" disabled={this.state.selectedMultipleItems.length == 0} onClick={() => this.handleResize(false)}>Смали маса -</button>
                                                            </>
                                                            :
                                                            null
                                                        }
                                                        {/* <button onClick={() => this.handleResizeEnd()}>Finish Resize</button> */}

                                                        {/* <button onClick={() => this.handleRemove()}>Изтрии маса</button> */}


                                                    </div>

                                                    <br />
                                                    <div style={{ margin: '0 auto', width: 'fit-content' }}>
                                                        {Object.values(this.props.restaurantHalls).map((hall) =>
                                                            <>
                                                                <button className={"btn btn-warning btn-ladda ladda-button " + (this.state.selectedRestaurantHallToShow == hall.id ? 'active' : '')} onClick={() => { this.prepareReservationData(); this.filterTablesByRestaurantHallId(hall.id) }}>{hall.name}</button>
                                                                &nbsp;
                                                            </>
                                                        )}
                                                    </div>

                                                    {this.state.selectedMultipleItems && Object.values(this.state.selectedMultipleItems).length > 0 ?
                                                        <>
                                                            {this.state.bookedForTodayReservationsTables && Object.values(this.state.bookedForTodayReservationsTables).length > 0 ?
                                                                <>
                                                                    {Object.values(this.state.bookedForTodayReservationsTables || []).filter(r => (this.state.selectedMultipleItems).includes(r.reservation_table.tableId)).map((r, k) => {
                                                                        // console.log('rerereeeerereer1', r);
                                                                        // let currentTableBooked = this.state.tablesWithNumbers.filter(a => a.tableId == r.reservation_table.tableId);
                                                                        // if (currentTableBooked && currentTableBooked[0] && currentTableBooked[0].tableNum) {
                                                                        //     currentTableBooked = currentTableBooked[0].tableNum;
                                                                        // }
                                                                        return <div>
                                                                            {k == 0 ? <>Предстоящи резервации за избраната маса:<br /></> : null}
                                                                            {/* <b>бр. {r.people_count} [{Object.values(r.allTables).map((tt, kk) => tt.table_num + '' + ((kk != Object.values(r.allTables).length - 1) ? ',' : ''))}]</b> - {r.client_name} / {moment(r.start_time).format('HH:mm')} - {moment(r.end_time).format('HH:mm')} */}
                                                                            <div style={{ border: '1px solid #ccc', padding: 7, borderRadius: 7, width: 'max-content' }}>
                                                                                <b>[{Object.values(r.allTables).map((tt, kk) => tt.table_num + '' + ((kk != Object.values(r.allTables).length - 1) ? ',' : ''))}]</b> - {r.client_name} /<span style={{ background: '#ebebeb', borderRadius: 5 }}>{moment(r.start_time).format('HH:mm')} - {moment(r.end_time).format('HH:mm')}</span>/ <b>{namesStatuses[r.status]}</b> / Гости: {r.people_count}
                                                                                <br />Меню: {r.menu_included ? <b>ДА</b> : 'НЕ'} / Избрана маса: {r.table_choosed ? <b>ДА</b> : 'НЕ'} / Капаро: {r.paid_ammount ? <b>{r.paid_ammount} лв.</b> : null}  /
                                                                                <br />Описание: {r.description}
                                                                            </div>
                                                                        </div>
                                                                    }
                                                                    )}
                                                                </>
                                                                :
                                                                null
                                                            }
                                                            {/* 
                                                            {this.state.upcomingReservationsTables && Object.values(this.state.upcomingReservationsTables).length > 0 ?
                                                                <>Очаква настаняване избраната маса:
                                                                    {Object.values(this.state.upcomingReservationsTables || []).filter(r => (this.state.selectedMultipleItems).includes(r.reservation_table.tableId)).map(r => {
                                                                        console.log('r', r);
                                                                        return <div>
                                                                            {r.client_name + ' / '} {moment(r.start_time).format('HH:mm')} - {moment(r.end_time).format('HH:mm')}
                                                                        </div>
                                                                     }
                                                                    )}
                                                                </>
                                                                :
                                                                null
                                                            } */}

                                                            {this.state.inProgressReservationsTables && Object.values(this.state.inProgressReservationsTables).length > 0 ?
                                                                <>

                                                                    {Object.values(this.state.inProgressReservationsTables || []).filter(r => (this.state.selectedMultipleItems).includes(r.reservation_table.tableId)).map((r, k) => {
                                                                        // console.log('rerereeeerereer', r);
                                                                        // let currentTableBooked = this.state.tablesWithNumbers.filter(a => a.tableId == r.reservation_table.tableId);
                                                                        // if (currentTableBooked && currentTableBooked[0] && currentTableBooked[0].tableNum) {
                                                                        //     currentTableBooked = currentTableBooked[0].tableNum;
                                                                        // }

                                                                        return <div>
                                                                            {k == 0 ? <>Има резервация за избраната маса:<br /></> : null}
                                                                            <div style={{ border: '1px solid #ccc', padding: 7, borderRadius: 7, width: 'max-content' }}>
                                                                                <b>[{Object.values(r.allTables).map((tt, kk) => tt.table_num + '' + ((kk != Object.values(r.allTables).length - 1) ? ',' : ''))}]</b> - {r.client_name} /<span style={{ background: '#ebebeb', borderRadius: 5, padding: 1 }}>{moment(r.start_time).format('HH:mm')} - {moment(r.end_time).format('HH:mm')}</span>/ <b>{namesStatuses[r.status]}</b> / Гости: {r.people_count}
                                                                                <br />Меню: {r.menu_included ? <b>ДА</b> : 'НЕ'} / Избрана маса: {r.table_choosed ? <b>ДА</b> : 'НЕ'} / Капаро: {r.paid_ammount ? <b>{r.paid_ammount} лв.</b> : null}  /
                                                                                <br />Описание: {r.description}
                                                                            </div>
                                                                        </div>
                                                                    }
                                                                    )}
                                                                </>
                                                                :
                                                                null
                                                            }



                                                        </>
                                                        :
                                                        null
                                                    }

                                                    <br />

                                                    <div style={{ width: '100%', height: '100%', overflow: 'scroll' }}>
                                                        <Stage
                                                            // width={window.innerWidth}
                                                            width={window.innerWidth < 1290 ? 1290 : window.innerWidth}
                                                            height={window.innerHeight < 950 ? 950 : window.innerHeight}
                                                        // onClick={this.handleCanvasClick}
                                                        >
                                                            <Layer>
                                                                {/* <Text text="Try to drag/resize a shape" /> */}
                                                                {Object.values(this.props.tables).filter(t => t.is_for_reservation == 1 && t.restaurantHallId == this.state.selectedRestaurantHallToShow).map((table) => {
                                                                    ////console.log('YOU SHOULD SEE TABLE', table, table.id);
                                                                    // const isSelected = selectedItem === table.id;
                                                                    const isSelected = selectedMultipleItems.includes(table.id);
                                                                    return (
                                                                        <React.Fragment key={table.id}>

                                                                            {this.state.selectedRestaurantHallToShow == 1 ?
                                                                                <>
                                                                                    <Text
                                                                                        text={`Бар\nBar`} // Replace with the number or text you want to display
                                                                                        fontSize={30} // Adjust the font size as needed
                                                                                        fill={'black'} // Color of the text
                                                                                        align="center" // Alignment of the text
                                                                                        x={555}
                                                                                        y={50}
                                                                                        verticalAlign='top'// Vertical alignment
                                                                                    />
                                                                                    <Text
                                                                                        text={`DJ`} // Replace with the number or text you want to display
                                                                                        fontSize={30} // Adjust the font size as needed
                                                                                        fill={'black'} // Color of the text
                                                                                        align="center" // Alignment of the text
                                                                                        x={565}
                                                                                        y={340}
                                                                                        verticalAlign='top'// Vertical alignment
                                                                                    />
                                                                                    <Text
                                                                                        text={`Вход\nEntrance`} // Replace with the number or text you want to display
                                                                                        fontSize={30} // Adjust the font size as needed
                                                                                        fill={'black'} // Color of the text
                                                                                        align="center" // Alignment of the text
                                                                                        rotationDeg={270}
                                                                                        x={1200}
                                                                                        y={525}
                                                                                        verticalAlign='top'// Vertical alignment
                                                                                    />
                                                                                    <Text
                                                                                        text={`Сцена\nStage`} // Replace with the number or text you want to display
                                                                                        fontSize={30} // Adjust the font size as needed
                                                                                        fill={'black'} // Color of the text
                                                                                        align="center" // Alignment of the text
                                                                                        x={700}
                                                                                        y={850}
                                                                                        verticalAlign='top'// Vertical alignment
                                                                                    />
                                                                                </>
                                                                                :
                                                                                (
                                                                                    this.state.selectedRestaurantHallToShow == 16 ?
                                                                                        <>
                                                                                            <Text
                                                                                                text={`Бар\nBar`} // Replace with the number or text you want to display
                                                                                                fontSize={30} // Adjust the font size as needed
                                                                                                fill={'black'} // Color of the text
                                                                                                align="center" // Alignment of the text
                                                                                                x={385}
                                                                                                y={300}
                                                                                                verticalAlign='top'// Vertical alignment
                                                                                            />
                                                                                            <Text
                                                                                                text={`Вход\nEntrance`} // Replace with the number or text you want to display
                                                                                                fontSize={30} // Adjust the font size as needed
                                                                                                fill={'black'} // Color of the text
                                                                                                align="center" // Alignment of the text
                                                                                                // rotationDeg={270}
                                                                                                x={600}
                                                                                                y={725}
                                                                                                verticalAlign='top'// Vertical alignment
                                                                                            />
                                                                                            <Text
                                                                                                text={`Сцена\nStage`} // Replace with the number or text you want to display
                                                                                                fontSize={30} // Adjust the font size as needed
                                                                                                fill={'black'} // Color of the text
                                                                                                align="center" // Alignment of the text
                                                                                                x={800}
                                                                                                y={10}
                                                                                                verticalAlign='top'// Vertical alignment
                                                                                            />
                                                                                        </>
                                                                                        :
                                                                                        null
                                                                                )
                                                                            }

                                                                            <EachReservationTableBox
                                                                                state={this.state}
                                                                                table={table}
                                                                                handleDragStart={this.handleDragStart}
                                                                                handleItemSelected={this.handleItemSelected}
                                                                                isSelected={isSelected}
                                                                                setActiveColorForTable={this.setActiveColorForTable}
                                                                                getReservationDataByTable={this.getReservationDataByTable}
                                                                                handleDragEnd={this.handleDragEnd}
                                                                            />



                                                                            {/* 

                                                                        <Text
                                                                            text={`<`} // Replace with the number or text you want to display
                                                                            fontSize={16} // Adjust the font size as needed
                                                                            fill="white" // Color of the text
                                                                            align="right" // Alignment of the text
                                                                            width={parseInt(table.w)} // Adjust width if necessary
                                                                            height={parseInt(table.h)} // Adjust height if necessary
                                                                            verticalAlign='bottom'// Vertical alignment
                                                                            // onClick={() => this.handleTextClick(table.id)} // Add onClick handler
                                                                        /> */}

                                                                            {/* <button onClick={() => this.handleResize()}>-</button>

                                                                        <button onClick={() => this.handleResize()}>-</button> */}


                                                                        </React.Fragment>
                                                                    );
                                                                })}
                                                            </Layer>
                                                        </Stage>
                                                    </div>
                                                    {selectedAction === 'resize' && (
                                                        <div>
                                                            Resizing Mode Active. Click on a shape's corner to resize it, or click "Finish Resize" to exit resizing mode.
                                                        </div>
                                                    )}
                                                </div>

                                                <br />
                                                <br />

                                                <div className="row">
                                                    <div className="col-md-3 col-sm-12">
                                                        <label >&nbsp;</label>
                                                        <button className="btn btn-info" style={{ width: '100%' }} onClick={this.clearFilter}>Изчисти филтър</button>
                                                    </div>

                                                    <div className="col-md-3 col-sm-12">
                                                        <div className="form-group">
                                                            <label htmlFor="name">{this.props.translations.orders.client_name}</label>
                                                            <input className={`form-control`} id="search_client_name" type="text" placeholder=""
                                                                name="search_client_name" onChange={this.onChangeText} value={this.state.search_client_name} />
                                                        </div>
                                                    </div>

                                                    <div className="col-md-3 col-sm-12">
                                                        <div className="form-group">
                                                            <label htmlFor="name">{this.props.translations.orders.client_phone}</label>
                                                            <input className={`form-control`} id="search_client_phone" type="text" placeholder=""
                                                                name="search_client_phone" onChange={this.onChangeText} value={this.state.search_client_phone} />
                                                        </div>
                                                    </div>

                                                    <div className="col-md-3 col-sm-12">
                                                        <label >&nbsp;</label>
                                                        <button className="btn btn-primary" style={{ width: '100%' }} onClick={this.makeFilter}>Търси</button>
                                                    </div>
                                                </div>

                                                <br />


                                                {/* <ul className="nav nav-tabs" role="tablist" style={{ display: 'flex' }}> */}
                                                {/* Vsichki */}
                                                {/* <li className="nav-item">
                                                        <a className={"nav-link border-left-0 " + (this.state.activeTab == 1 ? "active" : "")} data-toggle="tab" onClick={() => this.setState({ activeTab: 1 })} role="tab" aria-controls="home">
                                                            <b>{allReservations.length}</b> {this.props.translations.reservations.all}
                                                        </a>
                                                    </li> */}

                                                {/* Nepotvurdeni */}
                                                {/* <li className="nav-item">
                                                        <a className={"nav-link " + (this.state.activeTab == 2 ? "active" : "")} data-toggle="tab" onClick={() => this.setActiveTab(2, unseenReservationItems)} role="tab" aria-controls="home">
                                                            {notContirmedReservationItems.length} {this.props.translations.reservations.new} {unseenReservationItems > 0 ? <span className="badge badge-pill badge-danger">{unseenReservationItems}</span> : null}
                                                        </a>
                                                    </li> */}

                                                {/* Potvurdeni */}
                                                {/* <li className="nav-item">
                                                        <a className={"nav-link " + (this.state.activeTab == 3 ? "active" : "")} data-toggle="tab" onClick={() => this.setActiveTab(3, unseenReservationItems)} role="tab" aria-controls="home">
                                                            {confirmedReservationItems.length} {this.props.translations.reservations.confirmed}
                                                        </a>
                                                    </li> */}

                                                {/* Anulirani */}
                                                {/* <li className="nav-item">
                                                        <a className={"nav-link " + (this.state.activeTab == 4 ? "active" : "")} data-toggle="tab" onClick={() => this.setActiveTab(4, unseenReservationItems)} role="tab" aria-controls="home">
                                                            {canceledReservationItems.length} {this.props.translations.reservations.canceled}
                                                        </a>
                                                    </li> */}

                                                {/* Bez masa */}
                                                {/* <li className="nav-item">
                                                        <a className={"nav-link " + (this.state.activeTab == 5 ? "active" : "")} data-toggle="tab" onClick={() => this.setActiveTab(5, unseenReservationItems)} role="tab" aria-controls="home">
                                                            {withoutTableReservationItems.length} {this.props.translations.reservations.without_table}
                                                        </a>
                                                    </li>


                                                </ul> */}

                                                <button className="btn btn-default btn-ladda ladda-button" onClick={() => this.toggleAllResDescForToday()}>{this.state.showAllResDescForToday ? 'Скрии' : 'Покажи'} всички описания</button>

                                                <br />
                                                <br />

                                                {/* onClick={() => this.setState({ showAllResDescForToday: !!this.state.showAllResDescForToday })}>Покажи всички описания за днес</button> */}
                                                {/* {showAllResDescForToday} */}


                                                {this.state.showAllResDescForToday ?
                                                    <div style={{
                                                        display: 'grid',
                                                        gridTemplateColumns: '1fr 1fr 1fr',
                                                        gridGap: 5
                                                    }}>
                                                        {Object.values(this.state.bookedForTodayReservationsTables || []).map((r, k) => {
                                                            // console.log('r', r);
                                                            if (uniqueReservations.includes(r.reservationId)) {
                                                                return null; // Skip if id is a duplicate
                                                            }
                                                            uniqueReservations.push(r.reservationId); // Add the id to the tracking array
                                                            console.log('r', r);
                                                            return (
                                                                <div style={{ padding: 5, border: '1px solid #ccc', }}>
                                                                    {r.client_name} / <span style={{ background: '#ebebeb', borderRadius: 5, padding: 1 }}>{moment(r.start_time).format('HH:mm')} - {moment(r.end_time).format('HH:mm')}</span>/
                                                                    <br />
                                                                    МЕНЮ: {r.menu_included ? 'ДА' : 'НЕ'} / Избрана маса: {r.table_choosed ? <b>ДА</b> : 'НЕ'}
                                                                    <br />
                                                                    МАСИ: <b>[{r.allTables.map((tt, i) => tt.table_num + (i + 1 == r.allTables.length ? '' : ','))}]</b> / ОПИСАНИЕ: {r.description}
                                                                </div>
                                                            )
                                                        })}
                                                    </div>
                                                    :
                                                    null
                                                }


                                                <span className="legend-status-info-container-bottom" style={{ float: 'right', height: 50 }}>
                                                    <span className="legend-status-info-each" onClick={() => { this.setState({ filterListingRes: this.state.filterListingRes == 1 ? 2 : 1 }); this.handlePageChange(1); }}>{this.state.filterListingRes == 1 ? 'Покажи Всички' : 'Покажи Активни'}</span>
                                                    <span className="legend-status-info-each" style={{ background: 'yellow' }} onClick={() => { this.setState({ filterListingRes: 3 }); this.handlePageChange(1); }}>Очакващи до 2 часа</span>
                                                    <span className="legend-status-info-each" style={{ background: '#b1b1ff' }} onClick={() => { this.setState({ filterListingRes: 4 }); this.handlePageChange(1); }}>Закъсняващи до 30 мин</span>
                                                    <span className="legend-status-info-each" style={{ background: '#ff3636' }} onClick={() => { this.setState({ filterListingRes: 5 }); this.handlePageChange(1); }}>Заети маси</span>
                                                </span>

                                                <table className="table table-responsive-sm table-striped">
                                                    <thead>
                                                        <tr>
                                                            {/* <th>Номер</th> */}
                                                            <th style={{ width: '10%' }}>{this.props.translations.common.client}</th>
                                                            <th style={{ width: '25%' }}> {this.props.translations.reservations.date}</th>
                                                            <th style={{ width: '5%' }}>{this.props.translations.reservations.people_count}</th>
                                                            <th style={{ width: '25%' }}>{this.props.translations.reservations.restaurant_hall}</th>
                                                            <th style={{ width: '15%' }}>{this.props.translations.reservations.tables}</th>
                                                            <th style={{ width: '15%' }}>{this.props.translations.common.status}</th>
                                                            <th style={{ width: '5%' }}>Опции</th>
                                                            {/* <th>{this.props.translations.common.user}</th> */}
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        {(listedReservations).map((reservation, index) => {
                                                            console.log('reservation', reservation);
                                                            if (index >= start_offset && start_count < ITEMS_PER_PAGE) {
                                                                start_count++;
                                                                return <ListReservationsItem
                                                                    // filterListingRes={this.state.filterListingRes}
                                                                    key={reservation.id}
                                                                    reservation={reservation}
                                                                    reservationsForDate={this.state.reservationsForDate}
                                                                    reservationsForTime={this.state.reservationsForTime}
                                                                    // deleteReservation={this.deleteReservation}
                                                                    // activeDefaultLanguage={this.activeDefaultLanguage}
                                                                    translations={this.props.translations}
                                                                    currLang={this.props.currLang}
                                                                    autocompleteStatuses={autocompleteStatuses}
                                                                    onChangeSelectAutocompleteStatus={this.onChangeSelectAutocompleteStatus}
                                                                    currentTableHaveActiveRes={currentTableHaveActiveRes}
                                                                />
                                                            }
                                                        })}

                                                    </tbody>
                                                </table>

                                                <Pagination
                                                    className="pagination"
                                                    itemClass="page-item"
                                                    activePage={current_page}
                                                    activeClass="active"
                                                    linkClass="page-link"
                                                    prevPageText="<"
                                                    nextPageText=">"
                                                    firstPageText="<<"
                                                    lastPageText=">>"
                                                    itemsCountPerPage={ITEMS_PER_PAGE}
                                                    totalItemsCount={items_count}
                                                    pageRangeDisplayed={ITEMS_PAGE_RANGE_DISPLAYED}
                                                    onChange={this.handlePageChange}
                                                />
                                            </div>
                                        </div>

                                        :
                                        <div className="row" >

                                            {(listedReservations).map((reservation, index) => {
                                                if (index >= start_offset && start_count < ITEMS_PER_PAGE) {
                                                    start_count++;
                                                    return <ListReservationsItemBox
                                                        key={reservation.id}
                                                        reservation={reservation}
                                                        // deleteReservation={this.deleteReservation}
                                                        // activeDefaultLanguage={this.activeDefaultLanguage}
                                                        translations={this.props.translations}
                                                        currLang={this.props.currLang}
                                                        autocompleteStatuses={autocompleteStatuses}
                                                        onChangeSelectAutocompleteStatus={this.onChangeSelectAutocompleteStatus}
                                                    />
                                                }
                                            })}
                                        </div>

                                    }

                                    {/* <!-- /.col--> */}
                                </div>
                            </div>
                        </div>
                    </div >
                </main >
            </>
        )
    }
}

const mapStateToProps = (state, dispatch) => {
    let restaurantHallsSorted = state.restaurantHalls.restaurantHalls || []
    restaurantHallsSorted = Object.values(restaurantHallsSorted).sort((a, b) => a.priority - b.priority);


    return {
        items: state.reservations.reservations || [],
        page: Number(state.router.location.query.page) || 1,
        translations: state.lang,
        currLang: "bg",
        soundActivated: state.admin.soundActivated,
        // orderStyleType: state.admin.orderStyleType,
        settings: state.settings.settings,
        tables: state.tables.tables,
        tableRegions: state.tableRegions.tableRegions || [],
        restaurantHalls: restaurantHallsSorted || [],
        currentlyEditedReservation: state.reservations.currentlyEditedReservation,
    }
};

const mapDispatchToProps = (dispatch, props) => {
    return {
        dispatch,
        ...bindActionCreators({ listReservations, editReservation, addReservation, setSoundActivation, changeOrdersStyleType, listTables, removeTable, editTable, setCurrentlyEditedReservation, listTableRegions, listRestaurantHalls, markReservationsAsNotified }, dispatch)
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(ListReservationsPage);
